import { TruexGlobalDefiner } from './truex_global_definer';

export const TruexTestStepsBuilder = TruexGlobalDefiner.define("TruexTestStepsBuilder", function () {
    "use strict";

    var StepType = {
        action: "action",
        wait: "wait"
    };

    return function () {
        var steps = [];

        this.addAction = function(action) {
            steps.push({
                type: StepType.action,
                step: action
            });
        };

        this.addWait = function(wait) {
            steps.push({
                type: StepType.wait,
                step: wait
            });
        };

        this.asPromiseChain = function () {
            var promiseChain = Promise.resolve();

            for (var i = 0; i < steps.length; i++) {
                var thisStep = steps[i];

                if (i + 1 < steps.length && thisStep.type === StepType.action && steps[i + 1].type === StepType.wait) {
                    // Combine any action followed immediately by a wait into a "doActionAndWait" combination.
                    // This is to ensure we start listening for events before performing actions that produce them.
                    promiseChain = promiseChain.then(combinedDoActionAndWaitPromise(thisStep.step, steps[i + 1].step));

                    // Skip the next step because it has already been included in the promiseChain.
                    i++;
                } else {
                    promiseChain = promiseChain.then(thisStep.step);
                }
            }

            return promiseChain;
        }
    };

    function combinedDoActionAndWaitPromise(action, wait) {
        return function () {
            var waitPromise = wait();
            action();
            return waitPromise;
        };
    }
});
