import { TXMFocusManager } from 'truex-shared/focus_manager/txm_focus_manager';
import { inputActions } from 'truex-shared/focus_manager/txm_input_actions';
import { createFocusable } from './createFocusable';
import selectorOverlay from './selector-overlay.scss';

export class SelectorOverlay {
    constructor() {
        let rootDiv;
        let onSaveCallback;
        let selectorFocusables = [];

        const focusManager = new TXMFocusManager();
        focusManager.id = 'SelectorOverlay';

        const baseOnInputAction = focusManager.onInputAction;

        function onInputAction(action) {
            if (action == inputActions.back) {
                hide();
            } else {
                baseOnInputAction(action);
            }

            // Mark all actions as handled so it does not escape the modal,
            // particularly back actions.
            return true;
        }

        focusManager.onInputAction = onInputAction;

        function hide() {
            onSaveCallback = null; // release reference
            selectorFocusables = [];
            if (rootDiv && rootDiv.parentNode) {
                rootDiv.parentNode.removeChild(rootDiv);
                rootDiv = null;
            }

            // remove global event listeners
            focusManager.cleanup();
        }

        this.hide = hide;

        this.showCapTypes = function(parentElement, saveCallback) {
            this.show(["normal", "value_added"], parentElement, saveCallback);
        };

        this.showGeoRegions = function(parentElement, saveCallback) {
            const regions = ["None", "AL", "AK", "AS", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FM", "FL", "GA", "GU", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MH", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "MP", "OH", "OK", "OR", "PW", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VI", "VA", "WA", "WV", "WI", "WY"];
            this.show(regions, parentElement, saveCallback);
        };

        this.show = function(options, parentElement, saveCallback) {
            this.hide();
            onSaveCallback = saveCallback;

            rootDiv = document.createElement('div');
            rootDiv.classList.add('selector-overlay');
            rootDiv.classList.add('input-overlay');

            const optionsList = document.createElement('ul');
            optionsList.classList.add('selector-options');
            rootDiv.appendChild(optionsList);

            options.forEach((option) => {
                const listItem = document.createElement('li');
                listItem.className = 'action-button selector-item';
                const label = document.createElement('div');
                label.className = 'label';
                label.innerText = option;
                listItem.appendChild(label);
                optionsList.appendChild(listItem);
            });

            if (!parentElement) parentElement = document.body;
            parentElement.appendChild(rootDiv);

            createSelectorFocusables();
            addSelectorFocusables();

            focusManager.captureKeyboardFocus(rootDiv);
        }

        function addSelectorFocusables() {
            let contentFocusables = [];
            let rowFocusables = [];
            selectorFocusables.forEach((focusable, index) => {
                // 8 buttons fit on the screen so we want an array of 8 for the focusables
                if (index % 8 == 0 && index != 0) {
                    contentFocusables.push(rowFocusables);
                    rowFocusables = []
                }
                rowFocusables.push(focusable);
            });

            contentFocusables.push(rowFocusables);

            const initialFocus = selectorFocusables[0];

            focusManager.setContentFocusables(contentFocusables);
            focusManager.setFocus(initialFocus);
        }

        function createSelectorFocusables() {
            rootDiv.querySelectorAll('li').forEach(buttonDiv => {
                const label = buttonDiv.querySelector('.label');
                const buttonAction = () => {
                    const value = label.innerText == "None" ? "" : label.innerText;
                    onSaveCallback(value);
                    hide();
                };

                selectorFocusables.push(createFocusable(buttonDiv, buttonAction, focusManager));
            });
        }
    }
}
export default SelectorOverlay; 