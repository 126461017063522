import { AwsClient } from './aws_client';
import { JobQueueUrlProvider } from './job_queue_url_provider';
import { v4 as uuid } from 'uuid';

const baseQueuesUrl = "https://rowhcmie25.execute-api.us-east-1.amazonaws.com/v1";

export class JobQueueClient {
    constructor(config) {
        const platformName = config.platformName;

        const awsClient = (config && config.awsSqsClient) || new AwsClient();

        const queueUrl = new JobQueueUrlProvider({
            baseQueuesUrl: baseQueuesUrl
        }).getJobQueueUrl(platformName);

        function defaultSleepFunction(seconds) {
            return new Promise(resolve => {
                setInterval(resolve, seconds * 1000);
            });
        }

        const sleepFunction = (config && config.sleepFunction) || defaultSleepFunction;

        function checkForWork() {
            log('Checking for work...');

            return awsClient.receiveMessages(queueUrl)
                .then(messages => {
                    if (!Array.isArray(messages)) {
                        return null;
                    }
                    const firstUnstartedJobMessage = getFirstUnstartedMatchingJobMessage(messages);
                    return awsClient.releaseMessages(queueUrl, messages
                        .filter(message => {
                            return message !== firstUnstartedJobMessage;
                        })
                        .map(message => {
                            return message.receiptHandle;
                        }))
                        .then(() => {
                            if (!firstUnstartedJobMessage) {
                                return null;
                            }
                            const sessionId = uuid();
                            let matchingJob = JSON.parse(JSON.stringify(firstUnstartedJobMessage.job));
                            matchingJob.sessionId = sessionId;
                            return awsClient.deleteMessage(queueUrl, firstUnstartedJobMessage.receiptHandle)
                                .then(() => {
                                    return awsClient.sendMessage(queueUrl, matchingJob);
                                })
                                .then(() => {
                                    return matchingJob;
                                });
                        });
                });
        }

        function fetchJobSession(sessionId) {
            const attempt = createFetchJobSessionAttemptForSessionId(sessionId);
            return retryPromise(attempt, 5);
        }

        function log(message, data) {
            console.log("[JobQueueClient] " + message);
            if (data) console.dir(data);
        }

        function getFirstUnstartedMatchingJobMessage(messages) {
            return messages.filter(containsUnstartedJobMatchingPlatformName)[0];
        }

        function containsUnstartedJobMatchingPlatformName(message) {
            return message.job
                && !message.job.sessionId
                && message.job.desiredCapabilities
                && message.job.desiredCapabilities.platformName === platformName;
        }

        function getFirstMessageMatchingSessionId(messages, sessionId) {
            return messages.filter(message => {
                return message.sessionId === sessionId;
            })[0];
        }

        function retryPromise(fn, attemptsRemaining, err = null) {
            if (!attemptsRemaining) {
                return Promise.reject(err);
            }
            return fn().catch(err => {
                return sleepFunction(5)
                    .then(() => {
                        return retryPromise(fn, (attemptsRemaining - 1), err);
                    });
            });
        }

        function createFetchJobSessionAttemptForSessionId(sessionId) {
            return () => {
                return awsClient.receiveMessages(queueUrl)
                    .then(messages => {
                        if (!Array.isArray(messages)) {
                            throw new Error(`No matching session found for session: ${sessionId}`);
                        }
                        const firstMatchingMessage = getFirstMessageMatchingSessionId(messages, sessionId);
                        return awsClient.releaseMessages(queueUrl, messages
                            .filter(message => {
                                return message !== firstMatchingMessage;
                            })
                            .map(message => {
                                return message.receiptHandle;
                            }))
                            .then(() => {
                                if (!firstMatchingMessage) {
                                    throw new Error(`No matching session found for session: ${sessionId}`);
                                }
                                return awsClient.deleteMessage(queueUrl, firstMatchingMessage.receiptHandle)
                                    .then(() => {
                                        return firstMatchingMessage.job;
                                    });
                            });
                    });
            }
        }

        this.checkForWork = checkForWork;
        this.fetchJobSession = fetchJobSession;
    }
}
