export class JobQueueUrlProvider {
    constructor(config) {
        const baseQueuesUrl = config.baseQueuesUrl;

        function getJobQueueUrl(platformName) {
            return `${baseQueuesUrl}/${platformName.toLowerCase()}/jobs`;
        }

        this.getJobQueueUrl = getJobQueueUrl;
    }
}
