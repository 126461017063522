import { Focusable } from 'truex-shared/focus_manager/txm_focusable';

function createFocusable(element, selectAction, focusManager, inputAction) {
    const f = new Focusable(element, selectAction, inputAction);
    f.addMouseEventListeners(focusManager);

    f.onFocusSet = function(hasFocus, focusChange) {
        let e = f.element;
        if (!e) return;
        if (hasFocus) {
            e.classList.add("hasFocus");
            if (focusChange.event instanceof MouseEvent) {
                // Don't auto scroll with mouse overs, too disruptive for the user.
                return;
            }
            f.scrollIntoView(focusChange);
        } else {
            e.classList.remove("hasFocus");
        }
    };

    // The usual element.scrollIntoView() is pretty much busted on XboxOne, kind of strange, e.g this:
    //  this.element.scrollIntoView({behavior: "smooth", block: "nearest", inline: "start"});
    // or even:
    //  this.element.scrollIntoView();
    // causes the bottom of the parent container to scroll too far.
    //
    // So we work around that by doing it ourselves. This gives us better control anyway.
    f.scrollIntoView = function(focusChange) {
        const e = f.element;
        const elementBounds = e.getBoundingClientRect();
        var page;
        var container;
        for(var parent = e.parentElement; parent; ) {
            if (parent.classList.contains('page')) {
                page = parent;
                break;
            }
            container = parent;
            parent = parent.parentElement;
        }
        if (!page || !container) return;
        const pageBounds = page.getBoundingClientRect();
        const pageH = pageBounds.height;
        const elementY = e.offsetTop; // i.e. relative to the container
        const margin = 8;
        const maxScrollTop = container.offsetHeight - e.offsetHeight;
        const oldScrollTop = page.scrollTop || 0;
        var newScrollTop = oldScrollTop;
        if (elementBounds.top < pageBounds.top || this.shouldScrollToTop(focusChange)) {
            newScrollTop = elementY - margin;
        } else if (elementBounds.bottom > pageBounds.bottom) {
            newScrollTop = elementY - pageH + margin;
        }
        const fixedScrollTop = Math.max(0, Math.min(maxScrollTop, newScrollTop));
        if (fixedScrollTop != oldScrollTop) {
            if (page.scrollTo && !focusManager.platform.isSlowDevice) {
                page.scrollTo({
                    top: fixedScrollTop,
                    left: 0,
                    behavior: "smooth"
                });
            } else {
                // Fallback to scrollTop, i.e. on XboxOne, PS4
                page.scrollTop = fixedScrollTop;
            }
        }
    };

    // Override to customize.
    f.shouldScrollToTop = (focusChange) => false;

    return f;
}

export { createFocusable };
export default createFocusable;
