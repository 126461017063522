import 'whatwg-fetch';
import { inputActions } from 'truex-shared/focus_manager/txm_input_actions';
import { AppReloader } from './app_reloader';
import { JobQueueClient } from './job_queue_client';
import { TXMPlatform } from 'truex-shared/focus_manager/txm_platform';
import { TestResultsClient } from "./test_results_client";
import { ScreenSaver } from "./screen_saver";
import { runLocalTestScript } from "./local-test-script-stub/local-test-script";
import { TruexTest } from './truex-test/truex_test';

const pollingIntervalMs = 5000;

// Set to true to allow local debugging of a valid stubbed test script, without needing
// to actually communicate with or set up jobs in AWS.
const useStubbedTestScript = false;

export class TestWorker {

    /**
     * Config properties:
     * - platform (TXMPlatform from truex-shared)
     * - appReloader
     * - urlLoader
     * - jobQueueClient
     *
     * @param config
     */
    constructor(config) {
        const platform = config.platform || new TXMPlatform();
        const platformName = platform.name;

        const appReloader = config.appReloader || new AppReloader({
            urlLoader: config.urlLoader
        });

        const jobQueueClient = config.jobQueueClient || new JobQueueClient({
            platformName: platformName
        });

        const testResultsClient = config.testResultsClient || new TestResultsClient();

        const screenSaver = config.screenSaver || new ScreenSaver({
            platformName: platformName
        });

        let checkForWorkInterval = null;
        let automationModeRequested = false;

        function startLookingForWork() {
            automationModeRequested = true;
            screenSaver.activate();
            if (!checkForWorkInterval) {
                log("Started looking for work. Platform:", platformName);
                checkForWork();
                checkForWorkInterval = setInterval(checkForWork, pollingIntervalMs);
            }
        }

        function pauseLookingForWork() {
            screenSaver.deactivate();
            if (checkForWorkInterval) {
                log("Stopped looking for work.");
                clearInterval(checkForWorkInterval);
                checkForWorkInterval = null;
            }
        }

        function resumeLookingForWork() {
            if (!automationModeRequested) {
                return;
            }
            startLookingForWork();
        }

        function stopLookingForWork() {
            automationModeRequested = false;
            pauseLookingForWork();
        }

        function log(message, data) {
            console.log("[TestWorker] " + message);
            if (data) console.dir(data);
        }

        function checkForWork() {
            if (useStubbedTestScript) {
                log("starting local test script stubs")
                setTimeout(() => doJob({
                    testId: "Local Test Scripts",
                    sessionId: 123,
                    scripts: ["testAdOptIn", "testAdLoadAndBackOut", "testCanAutoAdvanceAfterAreYouSureYes"],
                }), 500);
                return;
            }

            log("Checking for work...");
            jobQueueClient.checkForWork()
                .then(doJob)
                .catch((error) => {
                    log("Error when doing job:", error);
                })
                .finally(resumeLookingForWork);
        }

        function doJob(job) {
            if (!job) {
                return;
            }
            log("Found a job!");
            screenSaver.deactivate();
            appReloader.prepareToResumeJobSession(job.sessionId);
            const { scripts, testId, resultsUrl } = job;
            if (!Array.isArray(scripts)) {
                log("Job doesn't have an array of scripts! Looking for more work...");
                return;
            }
            log("Job looks good, running...");
            pauseLookingForWork();
            return runScriptsAndGatherAllResults(scripts)
                .then(waitForAdCleanup)
                .then(postResults(testId, resultsUrl));
        }

        function runScriptsAndGatherAllResults(scripts) {
            const scriptsCopy = scripts.slice(0);
            let allResults = [];
            let promiseChain = Promise.resolve();
            while (scriptsCopy.length) {
                const nextScript = scriptsCopy.shift();
                promiseChain = promiseChain
                    .then(() => {
                        return runTestScript(nextScript);
                    })
                    .then((results) => {
                        allResults = addResults(allResults)(results);
                        return allResults;
                    });
            }
            return promiseChain
                .then(results => {
                    if (useStubbedTestScript) {
                        // Test that jobs resume after reloading.
                        appReloader.reloadApp(window.location.origin + window.location.pathname);
                    }
                    return results;
                })
                .catch((error) => {
                    return addResults(allResults)([
                        {
                            type: "fail",
                            timestamp: Date.now(),
                            message: error.reason ? (error.reason.message ? error.reason.message : error.reason) : error
                        }
                    ]);
                });
        }

        function addResults(resultsSoFar) {
            return (results => {
                if (useStubbedTestScript) {
                    // For stubbed runs, we only care about logging, not in-memory results
                    return resultsSoFar;
                }
                if (typeof results === "string") {
                    // Support plain strings e.g. whakapapa's "script loaded" messages
                    return resultsSoFar.concat(results);
                } else {
                    // Assume the results are in the format used by whakapapa's truex_test.js
                    return resultsSoFar.concat(results.map(result => {
                        const { type, timestamp, message } = result;
                        return `${type.toUpperCase()}::[${timestamp}] ${message}`
                    }));
                }
            })
        }

        /**
         *
         * @returns {Promise}
         */
        function waitForAdCleanup(results) {
            return new Promise(resolve => {
                // Wait a bit for test cleanup to settle down, in particular any history stack cleanup.
                setTimeout(() => {
                    resolve(results);
                }, 200);
            });
        }

        function postResults(testId, resultsUrl) {
            return arrayOfResults => {
                if (useStubbedTestScript) {
                    // Don't care about posting when running a local stub.
                    log("Job finished!");
                    return;
                }
                log("Job finished! Posting results...");
                return testResultsClient.postResults(testId, resultsUrl, arrayOfResults)
                    .then(() => {
                        return log("Posted results!");
                    })
                    .catch((error) => {
                        log("Failed to post results! error:", error);
                    });
            };
        }

        // Returns the result of running the specified JS script.
        // If a promise is returned, that is assumed to indicate then the test completes.
        function runTestScript(script) {
            // Ensure the TAR class and inputActions enumeration is globally available to the test script.
            TruexTest.reset();
            window.TruexTest = window.TruexTest || TruexTest;
            window.TruexTest.inputActions = inputActions;
            window.TruexTest.platform = platform;

            if (useStubbedTestScript) {
                return runLocalTestScript(script);
            }

            if (!script) return;
            return eval(script);
        }

        function resumeSessionJob(sessionJobId) {
            if (useStubbedTestScript) {
                log(`Resuming session job ${sessionJobId} with the local test script.`);
                startLookingForWork();
                return;
            }

            appReloader.beginResuming();
            log(`Resuming session job ${sessionJobId}`);

            function failOnNoMatchingJob() {
                const message = `Session job ${sessionJobId} not found.`;
                log(message);
                throw new Error(message);
            }

            return jobQueueClient.fetchJobSession(sessionJobId)
                .catch(failOnNoMatchingJob)
                .then(doJob)
                .catch((error) => {
                    log("Error when doing job:", error);
                })
                .finally(() => {
                    appReloader.stopResuming();
                    startLookingForWork();
                });
        }

        // Public functions.
        this.startLookingForWork = startLookingForWork;
        this.stopLookingForWork = stopLookingForWork;
        this.resumeSessionJob = resumeSessionJob;

        // Expose for unit testing.
        this.runTestScript = runTestScript;

        // Expose globally to make invokable from job scripts.
        window.reloadApp = appReloader.reloadApp;
    }
}
window.TestWorker = TestWorker;
