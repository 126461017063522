import { v4 as uuid }   from 'uuid';
import { encodeUrlParams } from 'truex-shared/utils/url_params';
import { TruexServers  } from 'truex-shared/utils/truex_servers';

import * as TarQA from '@truex/ad-renderer-QA/dist/tar';
import * as TarPreprod from '@truex/ad-renderer-QA/dist/tar.min';
import * as TarProd from '@truex/ad-renderer';

const adTags = require('./adTags.json');
const sampleVastConfig = require('./support/sampleVastConfig.json');

/**
 * Finds the ad tag with the specified name, throwing an error if not found, resolving it if so.
 * @param withName
 * @param tagRestrictions optional {@see resolveAdTag()}
 * @returns {*} ad tag object with usuable vastConfig or vast_config_url fields.
 */
export function getAdTag(withName, tagRestrictions) {
    const adTag = adTags.find(tag => {
        return tag.displayName == withName;
    });
    if (!adTag) {
        throw new Error('ad tag not found: ' + withName);
    }
    return resolveAdTag(adTag, tagRestrictions);
}

/**
 * Ensures the ad tag is usable for the TruexAdRenderer by ensuring either the vast_config_url or vastConfig fields
 * are present.
 *
 * @param srcAdTag object describing an ad to load, either via a vast_config_url field, a vastConfig field,
 *   ad_hash, or creative_draft_id fields. The last two are applied to a copy of the local {sampleVastConfig} file
 *   to use as the derived vastConfig value.
 * @param tagRestrictions optional, applied only if the adTag ultimately refers to a vast config object vs a
 *   vast config url, since the tag restrictions get appended to the final window_url.
 * @returns {*} ad tag object with usuable vastConfig or vast_config_url fields.
 */
export function resolveAdTag(srcAdTag, tagRestrictions) {
    const resultTag = {...srcAdTag}; // avoid aliasing bugs.

    const srcServers = new TruexServers(resultTag.vastConfig || resultTag.vast_config_url);
    const isQA = srcAdTag.isQA !== undefined ? srcAdTag.isQA
      : srcAdTag.environment !== undefined ? srcAdTag.environment != 'prod'
      : !srcServers.isProduction;
    resultTag.isQA = isQA;
    resultTag.isProduction = !isQA;
    resultTag.environment = isQA ? 'qa' : 'prod';

    const mode = srcAdTag.mode || "ctv";
    resultTag.mode = mode;

    const container = srcAdTag.container || "c3";
    resultTag.container = container;
    resultTag.isC2 = container == 'c2';

    resultTag.choiceCard = srcAdTag.choiceCard;

    if (!resultTag.displayName) {
        const envLabel = resultTag.isQA ? 'QA' : 'Prod';
        resultTag.displayName
          = resultTag.creative_draft_id ? `Creative Draft ID ${resultTag.creative_draft_id} ${envLabel}`
          : resultTag.ad_hash ? `Ad Hash ${resultTag.ad_hash} ${envLabel}`
            : firstAd.name;
    }

    if (!resultTag.detail) {
        const details = [];
        if (resultTag.foot_note) details.push(resultTag.foot_note);
        const adId = resultTag.creative_draft_id || resultTag.ad_hash;
        if (adId) details.push(adId);
        if (resultTag.isProduction) details.push('Prod');
        if (srcAdTag.container) details.push(resultTag.container.toUpperCase());
        if (details.length > 0) resultTag.detail = details.join(', ');
    }

    if (resultTag.vast_config_url) {
        // Ad tag is already usable, there is no available vast config (yet) to augment.
        return resultTag;
    }

    // Generate a usable vast config from the sample one:
    const vastConfig = JSON.parse(JSON.stringify(resultTag.vastConfig || sampleVastConfig)); // deep copy to avoid aliasing
    const firstAd = vastConfig.ads[0];
    if (!firstAd) {
        // Probably a skip card.
        return resultTag;
    }

    let windowUrl;
    let choiceCardUrl;
    if (resultTag.vastConfig) {
        // The ad tag has its own vast config.
        windowUrl = firstAd.window_url;
        choiceCardUrl = vastConfig.card_creative_url;

    } else {
        // We are referring to an ad in EE. Substitute the details into the sample vast config.
        const resultServers = new TruexServers(resultTag.isProduction);
        const exchangeHost = resultServers.serverUrlOf("ee.truex.com");
        let exchangeConfigUrl
        let exchangeAdUrl;
        if (resultTag.creative_draft_id) {
            const exchangeCreativeUrl = `${exchangeHost}/studio/drafts/${resultTag.creative_draft_id}`;
            exchangeConfigUrl = exchangeCreativeUrl + '/config_json';
            exchangeAdUrl = exchangeCreativeUrl + '/edit';
        } else if (resultTag.ad_hash) {
            exchangeAdUrl = `${exchangeHost}/ads/${resultTag.ad_hash}`;
            exchangeConfigUrl = exchangeAdUrl + '/config_json';
        } else {
            throw new Error("cannot resolve adTag: " + resultTag.displayName
                + "\n  missing vastConfig, vast_config_url, creative_draft_id, ad_hash fields");
        }
        resultTag.exchangeAdUrl = exchangeAdUrl;
        resultTag.exchangeConfigUrl = exchangeConfigUrl;

        const mediaServer = resultServers.mediaServerUrl;
        const engagementSessionId = uuid();
        const creativeArgsStart = resultTag.isC2 ? '?' : '#';
        const containerUrl = resultTag.isC2 ? `${resultServers.truexServerUrl}/engage`
          : `${mediaServer}/container/3.x/current/${mode}.html`

        windowUrl = `${containerUrl}${creativeArgsStart}creative_json_url=`
            + encodeURIComponent(exchangeConfigUrl) + '&session_id=' + engagementSessionId;
        choiceCardUrl = resultTag.choiceCard || `${mediaServer}/integration/${mode}/choicecard-${mode}.js`;

        vastConfig.ad_free_creative_url = choiceCardUrl;
        vastConfig.card_creative_url = choiceCardUrl;

        if (resultTag.card_creative_args) {
            // Use tag-specific creative args.
            vastConfig.card_creative_args = resultTag.card_creative_args;
        }
    }

    if (tagRestrictions) {
        const tagRestrictionsParams = encodeUrlParams(tagRestrictions);
        if (tagRestrictionsParams) {
            windowUrl += '&' + tagRestrictionsParams;
        }
    }

    let testVariants = resultTag.testVariants;
    if (!testVariants && !resultTag.vastConfig) {
        // Ensure new footer prompt and relevance changes are present by default in Skyline.
        testVariants = {"ctv_footer_test": "T0", "ctv_relevance_enabled": "true"};
        resultTag.testVariants = testVariants;
    }
    if (testVariants) {
        windowUrl += '&' + encodeUrlParams(testVariants, 'multivariate');

        firstAd.variants = testVariants;

        // Ensure we don't have contradicting service parameters (will fallback to window_url)
        firstAd.service_params = null;
    }

    if (resultTag.additionalParams){
        windowUrl += '&' + encodeUrlParams(resultTag.additionalParams)
    }

    firstAd.window_url = windowUrl;
    firstAd.name = resultTag.displayName;

    if (resultTag.layoutJSON) {
        // Enable the Bluescript based choice card.
        vastConfig.card_configurations[0].asset_type = "layoutJSON";
        vastConfig.card_configurations[0].asset_args = resultTag.layoutJSON;
    }

    resultTag.vastConfig = vastConfig;

    return resultTag;
}

export function getTarEnv(tagData, tarEnvSetting) {
    if (tarEnvSetting == 'auto') {
        return tagData.isProduction ? 'preprod' : 'qa';
    }
    return tarEnvSetting || 'qa';
}

export function getTarClass(tagData, tarEnv = 'qa') {
    tarEnv = getTarEnv(tagData, tarEnv);
    var tarModule;
    switch (tarEnv) {
        case 'preprod':
            tarModule = TarPreprod;
            break;
        case 'prod':
            tarModule = TarProd;
            break;
        case 'qa':
        default:
            tarModule = TarQA;
            break;
    }

    var tarClass;
    switch (tagData.mode) {
        case 'mobile':
            tarClass = tarModule.TruexAdRendererMobile;
            break;
        case 'desktop':
            tarClass = tarModule.TruexAdRendererDesktop;
            break;
        case 'ctv':
        default:
            tarClass = tarModule.TruexAdRendererCTV;
            break;
    }
    return tarClass;
}

export default getAdTag;
