import config from './config';
import { featureBranches, createLocalBranch } from './feature-branches';
import { inputActions } from 'truex-shared/focus_manager/txm_input_actions';
import adEvents from 'truex-shared/events/txm_ad_events';
import { TXMFocusManager } from 'truex-shared/focus_manager/txm_focus_manager';
import { AppStorage } from 'truex-shared/utils/app-storage';
import { DebugLog } from 'truex-shared/components/debug-log';
import { getAdTag, resolveAdTag, getTarClass, getTarEnv } from './adTagSupport';
import { getPageParts } from './getPageParts';
import { TestWorker } from './automation/test_worker';
import { TruexTest } from './automation/truex-test/truex_test';
import { parseQueryString } from './parseQueryString';
import { v4 as uuid }   from 'uuid';
import { createFocusable } from './components/createFocusable';
import { NumberKeyboard } from './components/number-keyboard';
import { SelectorOverlay } from './components/selector-overlay';

(function () {
    // Load our built in ad tags set.
    const adTags = require('./adTags.json');

    const focusManager = new TXMFocusManager();
    focusManager.id = 'Skyline'; // for identifying in debug logs

    const appStorage = new AppStorage();

    // Expose globally to make invokable from the native FireTV/AndroidTV launcher code.
    window.focusManager = focusManager;

    const platform = focusManager.platform;

    // Establish which branch of Skyline we are running under, if possible.
    // We assume any local network host is for local testing.
    const currentLocation = window.location.href.replace(/\/index\.html.*$/, '').replace(/\?.*$/, '').replace(/#.*$/, '');
    const currentHost = window.location.hostname;
    const isLocalBranch = ['localhost', '0.0.0.0', '127.0.0.1'].indexOf(currentHost) >= 0
        || currentHost.startsWith("192.168.") || currentHost.startsWith("10.0.");
    var currentBranch = isLocalBranch ? createLocalBranch(currentHost) : featureBranches.find(branch => {
        return branch.host.startsWith(currentLocation) || currentLocation.startsWith(branch.host);
    });
    if (platform.isPS5) {
        // The PS5 does not seem to allow for launchers that initially point to a local IP.
        // However, reloading the app to a local IP works fine.
        // Thus we ensure we have a designated local branch present for debugging.
        // Update to your local IP as needed.
        const debugBranch = isLocalBranch ? currentBranch : createLocalBranch('192.168.1.82');
        featureBranches.push(debugBranch);
    }
    if (!currentBranch) {
        // Ensure we always have a branch, so as to allow app reloading.
        const branchPathMatch = currentLocation.match(/^.*\/branch-test\/skyline\//);
        currentBranch = {
            label: branchPathMatch && currentLocation.substring(branchPathMatch[0].length) || currentLocation,
            host: window.location.href
        };
    }
    if (featureBranches.indexOf(currentBranch) < 0) {
        // Ensure we can always reload the current branch.
        featureBranches.push(currentBranch);
    }

    // Demonstrate key map overrides.
    const keyCodes = platform.keyCodes;
    const keyMapOverrides = {
        select: keyCodes.D,
            back: keyCodes.B,
            moveUp: keyCodes.E,
            moveDown: keyCodes.X,
            moveLeft: keyCodes.S,
            moveRight: keyCodes.F
    };
    platform.applyInputKeyMap(keyMapOverrides);

    const testWorker = new TestWorker({
        platform: platform
    });

    const tabBarFocusables = [];
    const adConfigBarFocusables = [];
    const adTagFocusables = [];
    const adTagSectionFocusables = [];
    const branchFocusables = [];
    const testControlFocusables = [];
    const creativeDraftIdFocusables = [];
    const tagRestrictionFocusables = [];
    let _creativeDraftId = "";

    let hasActiveFocusables = false;
    let lastFocus;

    let showingTabBar = true;
    let isRunningAutomationTests = false;

    let selectedTarEnv = 'auto';
    const userIdChoices = {
      random: 'random',
      fromVast: 'fromVast',
      usePrevious: 'usePrevious'
    };
    let currentUseIdChoice = userIdChoices.random;
    let currentUserAdvertisingId = uuid();
    let useCancelStream = false;
    let autoPauseAds = false;
    let useWebMAF = true; // only on PS4, but it is the usual case in our client apps.
    let adOverlay = null;
    let tar = null;
    let autoResumeTimeoutId = null;

    const debugLog = new DebugLog();
    debugLog.captureConsoleLog();

    let tagRestrictions = JSON.parse(appStorage.getItem('tagRestrictions')) || {};

    const appBuild = `${config.name} ${config.version} ${config.buildDate}`;

    const mainContent = document.querySelector('.main-content');

    const adTagsPage = document.querySelector('#all-ad-tags');
    const adDisplayPage = document.querySelector('#ad-display');
    const branchesPage = document.querySelector('#skyline-branches');
    const testControlPage = document.querySelector('#test-control');
    const creativeDraftIdPage = document.querySelector('#creative-draft-id');
    const tagRestrictionsPage = document.querySelector('#tag-restrictions');

    let currentContainer = 'c3';
    let currentMode = 'ctv';

    let useFreeWheelChoiceCard = false;
    const ccServer = currentBranch.choiceCardServerOverride || 'https://qa-media.truex.com/integration';
    const freeWheelChoiceCard = `${ccServer}/fw_renderers/choicecard.js`;

    let  lastParentPage; // for returning from the ad page.

    const adEventsContainer = document.querySelector('#adEvents');
    const adEventsContent = document.querySelector('#adEventItems');

    const appTitle = document.querySelector('#app-title');
    appTitle.innerText = appBuild + ' ' + currentBranch.label;

    console.log(appBuild);
    console.log(`branch: ${currentBranch.label}`);
    console.log(`commit: ${config.buildHash}`);

    const configMsg = `host: ${window.location.href}
platform: ${platform.name} model: ${platform.model} version: ${platform.version}
user agent: ${window.navigator.userAgent}`;
    console.log(configMsg);

    scaleToAppSize();
    buildDisplay();

    // Render the page every url hash change.
    window.addEventListener("hashchange", renderCurrentPage);

    window.addEventListener("resize", onAppResized);

    window.addEventListener("keydown", focusManager.onKeyDown);

    window.addEventListener("keyup", checkKeyEvents);
    window.addEventListener("keypress", checkKeyEvents);

    // Put in a mraid stub to allow the ad to at least be closed.
    window.mraid = {
        addEventListener: () => {},
        removeEventListener: () => {},
        open: url => handleAdEvent({ type: adEvents.popupWebsite, url }),
        close: () => handleAdEvent({ type: adEvents.adCompleted })
    };

    // Support mraid extensions used by mobile FreeWheel choice card renderers.
    window.mraid_ext = {
        start: () => {
            if (tar && tar.vastConfig && tar.vastConfig.tag_type == 'skip') {
                handleAdEvent({type: adEvents.skipCardShown});
            } else {
                handleAdEvent({type: adEvents.optIn});
            }
        },

        credit: () => {
            handleAdEvent({type: adEvents.adFreePod});
        },

        notify: notifyData => {
            if (notifyData == 'timeout') {
                handleAdEvent({type: adEvents.optOut});
            }
        }
    };

    function checkKeyEvents(event) {
        if (adOverlay) {
            // The TAR is supposed to swallow all keyboard events.
            console.warn(`${event.type} event not blocked by TAR: keyCode: ${event.keyCode}`);
        }
    }

    // NOTE: not the same as reloadApp use by the test worker, since that involves continuing automation scripts.
    function reloadSkyline(url) {
        if (!url) url = window.location.href;
        console.log('reloading Skyline: ' + url);
        setTimeout(doReload, 0);

        function doReload() {
            // Check if we are on FireTV and have the loadUrl function on the Java side.
            // Fire TV does not allow a web app to reload or redirect itself.
            const hostApp = window.hostApp;
            if (platform.isAndroidOrFireTV && hostApp && hostApp.loadUrl) {
                hostApp.loadUrl(url);
            } else if (url == window.location.href) {
                // Force a reload even if on the same page already.
                console.log("forced reload: " + url);
                window.location.reload(true);
            } else {
                console.log("reload open: " + url);
                window.open(url, '_self');
            }
        }
    }

    // Get TAR options appropriate for invoking and testing branch specific versions of
    // the choice card (integration core) and engagement (container core);
    function getTarOptions(mode, tagData) {
        if (!mode) mode = 'ctv';

        switch (currentUseIdChoice) {
            case userIdChoices.usePrevious:
                // Use existing user ad id.
                break;
            case userIdChoices.fromVast:
                // Let the VAST config's or config url's own id be used.
                currentUserAdvertisingId = null;
                break;
            case userIdChoices.random:
            default:
                // Refresh the user ad id to prevent ads from being correlated.
                currentUserAdvertisingId = uuid();
                break;
        }

        let choiceCardOverride = null;
        if (tagData.choiceCard) {
            // Override the choice card as per the ad tag.
            choiceCardOverride = tagData.choiceCard;
        } else if (currentBranch.choiceCardServerOverride) {
            // Override the choice card as per the current branch.
            choiceCardOverride = currentBranch.choiceCardServerOverride + `/${mode}/choicecard-${mode}.js`;
        }
        return {
            userAdvertisingId: currentUserAdvertisingId,
            supportsUserCancelStream: useCancelStream,
            supportsExitOnUserCancel: tagData.supportsExitOnUserCancel ? tagData.supportsExitOnUserCancel : false,
            whakapapa: true,
            appId: "com.truex.skyline",
            useIntegration: {
                name: 'Skyline',
                version: config.version
            },
            useWebMAF: useWebMAF, // indicate to use WebMAF videos for the PS4

            choiceCardUrlOverride: choiceCardOverride,

            // Not strictly a TAR option, but put here for convenience in downstream processing once we actually
            // get the vast config data.
            engagementServerOverride: currentBranch.engagementServerOverride,
        };
    }

    // Expose globally for automation scripts.
    window.reloadSkyline = reloadSkyline;
    TruexTest.reset();
    window.TruexTest = window.TruexTest || TruexTest;
    window.TruexTest.config = config;
    window.TruexTest.appBuild = appBuild;
    window.TruexTest.getTarOptions = getTarOptions;

    // Generates the tag list DOM, create all focusables.
    function buildDisplay() {
        // Create the tab bar focusables.
        const pageTabMap = {
            'ad-tags-tab': 'all-ad-tags',
            'test-control-tab': 'test-control',
            'creative-draft-id-tab': 'creative-draft-id',
            'branches-tab': 'skyline-branches',
            'tag-restrictions-tab': 'tag-restrictions',
            'debug-log-tab': 'debug-log',
        };
        document.querySelectorAll('.tab-title').forEach(buttonDiv => {
            const tabAction = () => {
                let pageKey = pageTabMap[buttonDiv.id];
                if (pageKey == 'debug-log') {
                    debugLog.show();
                } else if (pageKey) {
                    window.location.hash = pageKey;
                }
            };
            tabBarFocusables.push(createFocusable(buttonDiv, tabAction, focusManager));
        });

        document.querySelectorAll('#ad-config-bar .env-choice-item').forEach(choiceDiv => {
            const choiceInput = choiceDiv.querySelector('input');
            choiceInput.checked = (choiceInput.value == selectedTarEnv);

            const chooseAction = () => {
                selectedTarEnv = choiceInput.value;
                choiceInput.checked = true;
            };
            adConfigBarFocusables.push(createFocusable(choiceDiv, chooseAction, focusManager));
        });

        document.querySelectorAll('#ad-config-bar .user-id-choice-item').forEach(choiceDiv => {
            const choiceInput = choiceDiv.querySelector('input');
            choiceInput.checked = (choiceInput.value == currentUseIdChoice);

            const chooseAction = () => {
                currentUseIdChoice = choiceInput.value;
                choiceInput.checked = true;
            };
            adConfigBarFocusables.push(createFocusable(choiceDiv, chooseAction, focusManager));
        });

        document.querySelectorAll('#ad-config-bar .cancel-stream-item').forEach(choiceDiv => {
            const checkbox = choiceDiv.querySelector('input');
            checkbox.checked = useCancelStream;

            const selectAction = () => {
                useCancelStream = !useCancelStream;
                checkbox.checked = useCancelStream;
            };
            adConfigBarFocusables.push(createFocusable(choiceDiv, selectAction, focusManager));
        });

        document.querySelectorAll('#ad-config-bar .auto-pause-ads-item').forEach(choiceDiv => {
            const checkbox = choiceDiv.querySelector('input');
            checkbox.checked = autoPauseAds;

            const selectAction = () => {
                autoPauseAds = !autoPauseAds;
                checkbox.checked = autoPauseAds;
            };
            adConfigBarFocusables.push(createFocusable(choiceDiv, selectAction, focusManager));
        });

        if (platform.isPS4) {
            document.querySelectorAll('#ad-config-bar .webmaf-item').forEach(webMafDiv => {
                webMafDiv.style.display = 'inline-block';
                const checkbox = webMafDiv.querySelector('input');
                checkbox.checked = useWebMAF;

                const selectAction = () => {
                    useWebMAF = !useWebMAF;
                    checkbox.checked = useWebMAF;
                };
                adConfigBarFocusables.push(createFocusable(webMafDiv, selectAction, focusManager));
            });
        }

        // Build the ad tag list items.
        const tagsList = document.querySelector('#all-ad-tags .tags-list');
        let needNewLine = false;
        var currentSectionFocusable;
        var currentTagFocusables = [];
        adTags.forEach((adTag, tagIndex) => {
            var sectionFocusable;
            var sectionTagFocusables;
            if (typeof(adTag) == "string") {
                const sectionDivider = document.createElement('div');
                sectionDivider.className = 'creative-tag-divider';
                const sectionText = document.createElement('span');
                sectionText.innerText = adTag;
                sectionDivider.appendChild(sectionText);
                sectionDivider.appendChild(document.createElement('hr'));
                tagsList.appendChild(sectionDivider);

                const sectionIndex = adTagSectionFocusables.length;
                const focusableIndex = adTagFocusables.length;

                sectionFocusable = createFocusable(sectionDivider, null, focusManager, action => {
                    if (action == inputActions.moveUp && sectionIndex > 0) {
                        focusManager.setFocus(adTagSectionFocusables[sectionIndex - 1], action); // move to previous section
                    } else if (action == inputActions.moveDown && sectionIndex < adTagSectionFocusables.length - 1) {
                        focusManager.setFocus(adTagSectionFocusables[sectionIndex + 1], action); // move to next section
                    } else if (action == inputActions.moveRight) {
                        focusManager.setFocus(adTagFocusables[focusableIndex + 1], action); // move to first ad tag in section
                    } else {
                        return; // do default processing.
                    }
                    return true; // handled
                });

                sectionFocusable.shouldScrollToTop = function(focusChange) {
                    // When moving down, we wish to see as much of the section buttons as possible.
                    return focusChange && focusChange.action == inputActions.moveDown;
                };

                currentSectionFocusable = sectionFocusable;
                sectionTagFocusables = [];
                currentTagFocusables = sectionTagFocusables;
                adTagSectionFocusables.push(sectionFocusable);
                adTagFocusables.push(sectionFocusable);

                needNewLine = true;
                return;
            } else {
                sectionFocusable = currentSectionFocusable;
                sectionTagFocusables = currentTagFocusables;
            }

            const listItem = document.createElement('li');
            listItem.className = 'action-button ad-tag';

            const label = document.createElement('div');
            label.className = 'label';
            label.innerText = adTag.displayName;
            listItem.appendChild(label);

            const resolvedTag = resolveAdTag(adTag);
            if (resolvedTag.detail) {
                const adTagDetail = document.createElement('div');
                adTagDetail.className = 'ad-tag-detail';
                adTagDetail.innerText = resolvedTag.detail;
                listItem.appendChild(adTagDetail);
            }

            tagsList.appendChild(listItem);

            const tagAction = () => {
                lastParentPage = window.location.hash;
                window.location.hash = 'adTag/' + tagIndex;
            };
            const tagFocusable = createFocusable(listItem, tagAction, focusManager, function(action) {
                var isBackAction = (action == inputActions.back);
                const nextTagFocus = focusManager.findNextFocus(this, action, sectionTagFocusables);
                if (!nextTagFocus && action == inputActions.moveLeft) {
                    // No next focus; we are at a left margin ad tag. Move up to the section title instead.
                    isBackAction = true;
                } else if (nextTagFocus) {
                    // Give precendence to nagivation to ad tags in the current ad tag section.
                    focusManager.setFocus(nextTagFocus);
                    return true; // handled
                }
                if (isBackAction && sectionFocusable) {
                    // Back out to the current section.
                    focusManager.setFocus(sectionFocusable);
                    return true; // handled
                }
            });

            if (needNewLine) {
                tagFocusable.newLine = true;
                needNewLine = false;
            }
            adTagFocusables.push(tagFocusable);
            sectionTagFocusables.push(tagFocusable);
        });

        let configDetails = document.querySelector("#config");
        configDetails.innerText = configMsg;

        // Create the branch selector buttons.
        const branchesList = document.querySelector('#skyline-branches .branch-list');
        featureBranches.forEach(branch => {
            const buttonItem = document.createElement('li');
            buttonItem.classList.add('action-button');
            buttonItem.classList.add('branch-button');

            const label = document.createElement('div');
            label.classList.add('label');
            label.innerText = branch.label;
            buttonItem.appendChild(label);

            branchesList.appendChild(buttonItem);
            branchFocusables.push(createFocusable(buttonItem, () => {
                // Try to avoid loading a cached version.
                reloadSkyline(branch.host + '?ts=' + Date.now());
            }, focusManager))
        });

        // Create the test control focusables.
        document.querySelectorAll('#test-control li').forEach(buttonDiv => {
            let buttonAction = () => {};
            switch (buttonDiv.id) {
                case 'start-stop-button':
                    buttonAction = () => {
                        if (isRunningAutomationTests) {
                            stopAutomationTests();
                        } else {
                            startAutomationTests();
                        }
                    };
                    break;
                case 'reload-button':
                    buttonAction = reloadSkyline;
                    break;

                case 'injection-test':
                    buttonAction = () => { window.location.hash = 'injection-test' };
                    break;
            }
            testControlFocusables.push(createFocusable(buttonDiv, buttonAction, focusManager));
        });

        // Create the creative draft id focusables.
        creativeDraftIdPage.querySelectorAll('li').forEach(buttonDiv => {
            let buttonAction = () => {};
            switch (buttonDiv.id) {
                case 'start-creative-draft-id-with-qa':
                case 'start-creative-draft-id-with-prod':
                    buttonAction = () => {
                        lastParentPage = window.location.hash;
                        const useTarQA = buttonDiv.id == "start-creative-draft-id-with-qa";
                        window.location.hash = "adCreative/" + _creativeDraftId + '/' + useTarQA;
                    };
                    break;
                default:
                    if (buttonDiv.id.indexOf("numberpad-")==0) {
                        buttonAction = () => {
                            const keypress = buttonDiv.id.split("-")[1].toString();
                            onCreativeDraftIdKeyPress(keypress);
                        };
                    }
                    break;
            }
            creativeDraftIdFocusables.push(createFocusable(buttonDiv, buttonAction, focusManager));
        });

        creativeDraftIdPage.querySelectorAll('.container-config-choice-item').forEach(choiceDiv => {
            const choiceInput = choiceDiv.querySelector('input');
            choiceInput.checked = (choiceInput.value == currentContainer);

            const chooseAction = () => {
                currentContainer = choiceInput.value;
                choiceInput.checked = true;
            };
            creativeDraftIdFocusables.push(createFocusable(choiceDiv, chooseAction, focusManager));
        });

        creativeDraftIdPage.querySelectorAll('.mode-config-choice-item').forEach(choiceDiv => {
            const choiceInput = choiceDiv.querySelector('input');
            choiceInput.checked = (choiceInput.value == currentMode);

            const chooseAction = () => {
                currentMode = choiceInput.value;
                choiceInput.checked = true;
            };
            creativeDraftIdFocusables.push(createFocusable(choiceDiv, chooseAction, focusManager));
        });

        creativeDraftIdPage.querySelectorAll('.fw-config-choice-cc').forEach(fwCcDiv => {
            const ccInput = fwCcDiv.querySelector('input');
            ccInput.checked = useFreeWheelChoiceCard;

            const toggleAction = () => {
                useFreeWheelChoiceCard = !ccInput.checked;
                setTimeout(() => ccInput.checked = useFreeWheelChoiceCard, 0);
            };
            creativeDraftIdFocusables.push(createFocusable(fwCcDiv, toggleAction, focusManager));
        });

        // Create the tag restrictions id focusables.
        document.querySelectorAll('#tag-restrictions li').forEach(buttonDiv => {
            let buttonAction;
            if (buttonDiv.classList.contains("number-input")) {
                buttonAction = showTagNumberInput;

            } else if (buttonDiv.classList.contains("region-input")) {
                buttonAction = showTagRegionInput;

            } else if (buttonDiv.classList.contains("cap-type-input")) {
                buttonAction = showTagCapTypeInput;

            } else if (buttonDiv.classList.contains("date-input")) {
                buttonAction = tagId => showTagNumberInput(tagId, true);

            } else if (buttonDiv.id.indexOf("clear-button") == 0) {
                buttonAction = clearTagRestrictions;
            }
            const selectAction = () => {
                lastFocus = focusManager.currentFocus;
                if (buttonAction) buttonAction(buttonDiv.id);
            };
            tagRestrictionFocusables.push(createFocusable(buttonDiv, selectAction, focusManager));
        });
    }

    function removeAllChildrenFrom(parent) {
        if (parent) {
            const childNodes = parent.children;
            for (let i = childNodes.length - 1; i >= 0; i--) {
                parent.removeChild(childNodes[i]);
            }
        }
    }

    function hideAdOverlay() {
        if (tar) tar.stop();
        tar = null;
        if (adOverlay) {
            if (adOverlay.parentNode) adOverlay.parentNode.removeChild(adOverlay);
            adOverlay = null;
        }

        // Ensure old style choice card is removed.
        const choiceCard = document.querySelector('body > #choicecard');
        if (choiceCard) choiceCard.parentElement.removeChild(choiceCard);
        const adFrame = document.querySelector('body > iframe');
        if (adFrame) adFrame.parentElement.removeChild(adFrame);

        // Ensure app content is visible again.
        mainContent.style.display = 'block';

        document.body.focus();
    }

    function hidePage() {
        // Hide whatever page is currently shown.
        mainContent.querySelectorAll('.page').forEach(page => {
            page.classList.remove('visible');
        });

        // Unselect all tabs
        document.querySelectorAll('.tab-title').forEach(tab => {
            tab.classList.remove('selected');
        });

        // Ensure any obsolete ad content is removed.
        hideAdOverlay();
    }

    function renderCurrentPage() {
        hidePage();
        const { pageKey, tagIndex, pathParts } = getPageParts(window.location.hash);
        enableStyle('#ad-config-bar', 'visible', false);

        let newTab;
        if (testVastConfigUrl && !pageKey) {
            // Allow direct testing of arbitrary placement urls.
            showAdPage({
                displayName: "Test Vast Config",
                vast_config_url: testVastConfigUrl
            });

        } else if (!pageKey || pageKey == "#all-ad-tags") {
            // Home page: show all the tags.
            newTab = '#ad-tags-tab';
            enableStyle('#all-ad-tags', 'visible', true);
            enableStyle('#ad-config-bar', 'visible', true);

        } else if (pageKey == "#skyline-branches") {
            newTab = '#branches-tab';
            enableStyle(pageKey, 'visible', true);

        } else if (pageKey == "#test-control") {
            newTab = '#test-control-tab';
            enableStyle('#test-control', 'visible', true);
            enableStyle('#ad-config-bar', 'visible', true);

        } else if (pageKey == "#creative-draft-id") {
            newTab = '#creative-draft-id-tab';
            enableStyle('#creative-draft-id', 'visible', true);
            enableStyle('#ad-config-bar', 'visible', true);

        } else if (pageKey == "#tag-restrictions") {
            newTab = '#tag-restrictions-tab';
            showTagRestrictionsPage();

        } else if (pageKey == "#adTag" && tagIndex >= 0) {
            // Render a specified ad tag.
            showAdPage(adTags[tagIndex]);

        } else if (pageKey == "#adCreative") {
            showAdPage({
                creative_draft_id: pathParts[1],
                isQA: pathParts[2] != 'false',
                mode: currentMode,
                container: currentContainer,
                choiceCard: useFreeWheelChoiceCard ? freeWheelChoiceCard : undefined
            });

        } else if (pageKey == "#injection-test") {
            runInjectionTest();

        } else if (pageKey == "#ad-display") {
            enableStyle('#ad-display', 'visible', true);

        } else {
            // If the keyword isn't listed in the above - render the error page.
            enableStyle('#error', 'visible', true);
        }


        showingTabBar = !!newTab;
        enableStyle('#tab-bar', 'visible', showingTabBar);
        if (newTab) {
            enableStyle(newTab, 'selected', true);
        }

        recomputeFocusables();
    }

    let resizeTimer;

    function onAppResized() {
        // Just push out the timer some more until things settle.
        if (resizeTimer) clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
            scaleToAppSize();
            recomputeFocusables();
        }, 100);
    }

    function scaleToAppSize() {
        // Ensure our app shows at a consistent size.
        const screenW = window.innerWidth;
        const screenH = window.innerHeight;
        const designW = 1920;
        const designH = 1080;
        const scaleFactor = Math.min(screenW / designW);
        const scaleTransform = `scale(${scaleFactor})`;
        const scaleOrigin = "0px 0px";

        const body = document.body;
        body.style.transformOrigin = scaleOrigin;
        body.style.webkitTransformOrigin = scaleOrigin;
        body.style.transform = scaleTransform;
        body.style.webkitTransform = scaleTransform;

        // Now put the app size in design coordinates, ensuring that the height still fills the
        // actual screen.
        body.style.width = "" + designW + "px";
        body.style.height = "" + (screenH / scaleFactor) + "px";

        console.log(`screen size: ${screenW} ${screenH} scale: ${scaleFactor}`)
    }

    function recomputeFocusables() {
        resizeTimer = undefined;

        // Recompute focus grid:
        let contentFocusables = [];
        if (showingTabBar) {
            // Put the tab bar focuses along the top.
            contentFocusables = contentFocusables.concat(tabBarFocusables);
        }

        // Remember the last used focus for when we return.
        let prevFocus = lastFocus;
        let currFocus = focusManager.currentFocus;
        if (currFocus) {
            lastFocus = currFocus;
        }

        let initialFocus;

        if (document.querySelector('#ad-config-bar.visible')) {
            contentFocusables = contentFocusables.concat(adConfigBarFocusables);
        }

        if (isPageVisible(adTagsPage)) {
            // We have the grid of ad tag buttons that fits within the page.
            const pageWidth = adTagsPage.clientWidth;
            const firstItem = adTagsPage.querySelector('li');
            const itemWidth = firstItem.clientWidth;
            const margin = 20;
            const maxCols = Math.floor((pageWidth - 2*margin) / itemWidth) || 1;

            const itemGap = 14;
            const listWidth = maxCols * (itemWidth + itemGap);
            const tagsList = adTagsPage.querySelector('.tags-list');
            tagsList.style.width = "" + listWidth + "px";

            contentFocusables = contentFocusables.concat(adTagFocusables);

            initialFocus = (prevFocus && adTagFocusables.indexOf(prevFocus) >= 0) ? prevFocus : adTagFocusables[0];

        } else if (isPageVisible(branchesPage)) {
            // We have a vertical column of branches buttons.
            contentFocusables = contentFocusables.concat(branchFocusables);
            initialFocus = (prevFocus && branchFocusables.indexOf(prevFocus) >= 0) ? prevFocus : branchFocusables[0];

        } else if (isPageVisible(testControlPage)) {
            // We have a vertical column of test buttons.
            contentFocusables = contentFocusables.concat(testControlFocusables);
            initialFocus = (prevFocus && testControlFocusables.indexOf(prevFocus) >= 0) ? prevFocus : testControlFocusables[0];

        } else if (isPageVisible(creativeDraftIdPage)) {
            // We have a number pad follow by a vertical column of Start buttons.
            contentFocusables = contentFocusables.concat(creativeDraftIdFocusables);
            initialFocus = (prevFocus && creativeDraftIdFocusables.indexOf(prevFocus) >= 0) ? prevFocus : creativeDraftIdFocusables[0];

        } else if (isPageVisible(tagRestrictionsPage)) {
            contentFocusables = contentFocusables.concat(tagRestrictionFocusables);
            initialFocus = (prevFocus && tagRestrictionFocusables.indexOf(prevFocus) >= 0) ? prevFocus : tagRestrictionFocusables[0];
        }

        focusManager.setContentFocusables(contentFocusables, initialFocus);
        hasActiveFocusables = contentFocusables.length > 0;
    }

    function onBackAction() {
        if (testVastConfigUrl) {
            return false; // use default back handling
        }

        const adTagsPage = document.querySelector('#all-ad-tags');
        if (isPageVisible(adTagsPage)) {
            // Just scroll to the top.
            adTagsPage.scrollTop = 0;
            focusManager.setFocus(tabBarFocusables[0]);

        } else {
            // Return to prev parent page or else home page.
            window.location.hash = lastParentPage || 'all-ad-tags';
            lastParentPage = undefined;
        }

        return true; // handled
    }

    const baseOnInputAction = focusManager.onInputAction;

    focusManager.onInputAction = (action) => {
        if (hasActiveFocusables) {
            // Only apply the input action to the current focus if it is visible.
            const handled = baseOnInputAction(action);
            if (handled) return true;
        }

        if (isPageVisible(adDisplayPage)) {
            if (onAdPageKeyInput(action)) return true;
        }

        if (isPageVisible(creativeDraftIdPage)) {
            const isNumberKey = parseInt(action) >= 0;
            if (isNumberKey) {
                onCreativeDraftIdKeyPress(action);
                return true;
            }
        }

        if (action == inputActions.num4 || action == inputActions.leftStick || action == inputActions.menu) {
            // Show debug log with either "4" on the remote, or clicking the left stick on the game controller.
            // Or the menu key, e.g. for FireTV
            debugLog.show();
            return true;
        }

        if (action == inputActions.exit) {
            platform.exitApp();
            return true;
        }

        if (action == inputActions.back) {
            return onBackAction();
        }
        return false; // not handled
    };

    function enableStyle(elementOrSelector, cssStyle, enabled) {
        let element = (typeof elementOrSelector == 'string')
            ? document.querySelector(elementOrSelector) : elementOrSelector;
        if (enabled) {
            element.classList.add(cssStyle);
        } else {
            element.classList.remove(cssStyle);
        }
    }

    function isPageVisible(pageOrSelector) {
        let page = (typeof pageOrSelector == 'string') ? document.querySelector(pageOrSelector) : pageOrSelector;
        let visiblePage = document.querySelector('.page.visible');
        return visiblePage === page;
    }

    function showWebsite(url) {
        // Open in a new window / tab
        window.open(url, '_blank');
    }

    function showAdPage(tagData, testLogic) {
        if (!tagData) return;

        clearTimeout(autoResumeTimeoutId);
        autoResumeTimeoutId = null;

        tagData = resolveAdTag(tagData, tagRestrictions);

        // hide the app contents so any WebMAF video can show underneath.
        mainContent.style.display = 'none';

        enableStyle('#ad-config-bar', 'visible', false);

        let page = document.querySelector('#ad-display');
        page.querySelector('h3').innerText = tagData.displayName;

        const adError = page.querySelector('#adError');
        adError.innerText = '';

        const detail = page.querySelector('#detail');
        removeAllChildrenFrom(detail);

        function addDetail(text, allowHTML) {
            const p = document.createElement('p');
            if (allowHTML){
                p.innerHTML = text;
            } else {
                p.innerText = text;
            }
            detail.appendChild(p);
            console.log(text);
            return p;
        }

        let adParams = tagData.adParams;
        let vastConfigUrl = tagData.vast_config_url;
        let vastConfig = tagData.vastConfig;

        if (tagData && tagData.creative_draft_id) {
            addDetail(`creative_draft_id: <a target="_blank" href="${tagData.exchangeAdUrl}">${tagData.creative_draft_id}</a>`, true);
        }
        if (tagData && tagData.ad_hash) {
            addDetail(`ad_hash: <a target="_blank" href="${tagData.exchangeAdUrl}">${tagData.ad_hash}</a>`, true);
        }

        let tarData;
        if (adParams) {
            tarData = adParams;
            addDetail('placement: ' + adParams.placement_hash);
        } else if (vastConfigUrl) {
            tarData = vastConfigUrl;
            addDetail('vast_config_url: ' + vastConfigUrl);
        } else if (vastConfig) {
            tarData = vastConfig;
            const placement = vastConfig.placement || {};
            addDetail('placement: ' + placement.identifier_hash);
        }

        enableStyle(page, 'visible', true);
        removeAllChildrenFrom(adEventsContent);

        const options = {
            ...getTarOptions(tagData.mode, tagData),
            trackingObserver: trackingObserver,
            keyMapOverrides: keyMapOverrides // Demonstrate key map overrides in the ad
        };

        tar = null;

        try {
            // We render the ad on top of the current page via TAR
            const finalTarEnv = getTarEnv(tagData, selectedTarEnv);
            const tarClass = getTarClass(tagData, finalTarEnv);
            tar = new tarClass(tarData, options);
            addDetail(`using ${finalTarEnv} ${tagData.mode} TAR ${tar.version} on ${tagData.container}`);
            tar.subscribe(handleAdEvent);

            addDetail('supports user cancel stream: ' + useCancelStream);
            addDetail('user id choice: ' + currentUseIdChoice);

            return tar.init()
                .then(vastConfig => {
                    const choiceCard = options.choiceCardUrlOverride || vastConfig.card_creative_url;
                    addDetail('choice card: ' + choiceCard);
                    addDetail('user ad id: ' + tar.userAdvertisingId);

                    if (options.engagementServerOverride) {
                        // Override the engagement as per the current branch.
                        const firstAd = vastConfig.ads && vastConfig.ads[0];
                        if (firstAd) {
                            let engagementServer = options.engagementServerOverride;
                            if (!engagementServer.endsWith('/')) engagementServer += '/';
                            firstAd.window_url = firstAd.window_url.replace(
                                /https:\/\/(qa-)?media.truex.com\/container\/3.x\/current\//, engagementServer);
                        }

                        // This is for Xtended View; xtended_view_fill is an array of engagement which is supposted to be a linear ad
                        const firstXvAd = vastConfig.ads && vastConfig.xtended_view_fill && vastConfig.xtended_view_fill[0];
                        if (firstXvAd) {
                            let engagementServer = currentBranch.engagementServerOverride;
                            if (!engagementServer.endsWith('/')) engagementServer += '/';
                            firstXvAd.window_url = firstXvAd.window_url.replace(
                                /https:\/\/(qa-)?media.truex.com\/container\/3.x\/current\//, engagementServer);
                        }
                    }

                    if (autoPauseAds) {
                        // Exercise pausing an ad before choice card is even present.
                        tar.pause();

                        // Auto resume after 10 seconds.
                        autoResumeTimeoutId = setTimeout(() => {
                            if (tar) tar.resume();
                        }, 10 * 1000);
                    }

                    // Disable device filtering
                    // var cardArgs = vastConfig.card_creative_args;
                    // delete cardArgs.disable_fireos6_non4k;
                    // delete cardArgs.disable_override_for_network_user_id;
                    // delete cardArgs.disable_user_agent_regex_match;

                    return tar.start(vastConfig);
                })
                .then(newAdOverlay => {
                    adOverlay = newAdOverlay;

                    // Run any optional test.
                    if (testLogic) {
                        return testLogic(tar);
                    }
                })
                .catch(err => {
                    handleAdError(err);
                });

        } catch (err) {
            handleAdError(err);
        }
    }

    function trackingObserver(category, name, value, isInteraction) {
        const isPixel = category == 'pixel';
        const prefix = isPixel ? 'pixel' : isInteraction ? 'interaction' : 'tracking';
        let msg = prefix + ': ' + (isPixel ? name : category + ', ' + name);
        if (value) msg += ', ' + value;
        console.log(msg);
        const par = document.createElement('p');
        par.innerText = msg;
        adEventsContent.appendChild(par);
    }

    function handleAdEvent(event) {
        const par = document.createElement('p');
        par.innerText = 'ad event: ' + JSON.stringify(event);
        adEventsContent.appendChild(par);

        switch (event.type) {
            case adEvents.adError:
                handleAdError(event.errorMessage);
                break;
            case adEvents.userCancelStream:
            case adEvents.noAdsAvailable:
            case adEvents.adCompleted:
                hideAdOverlay();
                break;

            case adEvents.popupWebsite:
                showWebsite(event.url);
                break;
        }
    }

    function handleAdError(errOrMsg) {
        const msg = typeof errOrMsg == 'string' ? errOrMsg : errOrMsg.toString();
        console.error('ad error: ' + msg);
        adError.innerText = msg;
        hideAdOverlay();
    }

    function onAdPageKeyInput(action) {
        var direction = 0;
        switch (action) {
            case inputActions.moveUp:
                direction = -1;
                break;
            case inputActions.moveDown:
                direction = 1;
                break;
        }
        var containerH = adEventsContainer.offsetHeight;
        var contentH = adEventsContent.offsetHeight;
        if (direction && contentH > containerH) {
            // Scroll as per up/down keys.
            const step = 100 * direction;
            const maxTop = contentH - containerH;
            const currTop = adEventsContainer.scrollTop;
            adEventsContainer.scrollTop = Math.min(maxTop, Math.max(0, currTop + step));
            return true; // handled
        }
        return false;
    }

    function showTagRestrictionsPage() {
        enableStyle('#tag-restrictions', 'visible', true);
        refreshTagRestrictionsDisplay();
    }

    function refreshTagRestrictionsDisplay() {
        document.querySelectorAll('#tag-restrictions li').forEach(tagItem => {
            const tagId = tagItem.id;
            const tagDisplay = document.getElementById(tagId + '-display');
            if (tagDisplay) tagDisplay.innerText = tagRestrictions[tagId] || "";
        });
    }

    function showTagCapTypeInput(tagId) {
        const selectorOverlay = new SelectorOverlay();
        selectorOverlay.showCapTypes(mainContent, newValue => saveTagRestriction(tagId, newValue));
    }

    function showTagRegionInput(tagId) {
        const selectorOverlay = new SelectorOverlay();
        selectorOverlay.showGeoRegions(mainContent, newValue => saveTagRestriction(tagId, newValue));
    }

    function showTagNumberInput(tagId, isDateField) {
        const inputValue = tagRestrictions[tagId];
        const numberKeyboard = new NumberKeyboard();
        numberKeyboard.show(inputValue, isDateField, mainContent, newValue => saveTagRestriction(tagId, newValue));
    }

    function saveTagRestriction(tagId, newValue) {
        tagRestrictions[tagId] = newValue;
        appStorage.setItem('tagRestrictions', JSON.stringify(tagRestrictions));
        refreshTagRestrictionsDisplay();
    }

    function clearTagRestrictions() {
        tagRestrictions = {};
        appStorage.removeItem('tagRestrictions');
        refreshTagRestrictionsDisplay();
    }

    function startAutomationTests(sessionJobId) {
        if (isRunningAutomationTests) return;

        console.log("starting automation tests...");

        if (sessionJobId) {
            testWorker.resumeSessionJob(sessionJobId);
        } else {
            testWorker.startLookingForWork();
        }

        console.log("automation tests started");
        isRunningAutomationTests = true;
        setAutomationButtonLabel('Stop Automation Tests');
    }

    function stopAutomationTests() {
        if (!isRunningAutomationTests) return;

        console.log("stopping automation tests...");
        testWorker.stopLookingForWork();
        console.log("automation tests stopped");
        isRunningAutomationTests = false;
        setAutomationButtonLabel('Start Automation Tests');
    }

    function setAutomationButtonLabel(text) {
        document.querySelector(`#test-control #start-stop-button .label`)
            .innerText = text;
    }

    function disableScreenSaver() {
        // NOTE: platform specific. Some platforms disable the screen saver on the native ide, e.g. FireTV/AndroidTV
        if (platform.isTizen) {
            const webapis = window.webapis;
            const appcommon = webapis && webapis.appcommon;
            const supportScreensaver = !!(appcommon && appcommon.setScreenSaver && appcommon.AppCommonScreenSaverState);
            if (supportScreensaver) {
                // Ensure screen saver is disabled during playback.
                appcommon.setScreenSaver(appcommon.AppCommonScreenSaverState.SCREEN_SAVER_OFF, () => {
                    console.log("tizen screen saver disabled");
                }, error => {
                    console.warn("tizen screen saver NOT disabled: " + JSON.stringify(error));
                });
            }
        }
    }

    function fail(msg) {
        throw new Error(`test failure: ${msg}`);
    }

    function verify(context, actual, expected) {
        if (actual != expected) {
            fail(`${context}: actual: ${actual} expected: ${expected}`);
        } else {
            // for debugging:
            //console.log(`*** ${context}: ${actual}`);
        }
    }

    function runInjectionTest() {
        const adTag = getAdTag("Test: Kung Fu Panda (via vastConfig)");
        adTag.displayName = "Injection Test with Kung Fu Panda";
        showAdPage(adTag, testLogic);

        function testLogic(tar) {
            return tar.getCurrentFocusPath()
                .then(focusPath => {
                    verify('initial focus', focusPath, 'choice-card: #interact-btn');
                    // select choice card's interaction button
                    return tar.inject(1000, inputActions.select);
                })
                .then(focusPath => {
                    // Verify we can still safely query the focus while the ad is loading.
                    verify('focus while loading', focusPath, 'choice-card: #interact-btn');
                    return tar.inject(6000); // wait for the ad to load
                })
                .then(focusPath => {
                    verify('ad focus', focusPath, 'engagement-ad: #button_6');
                    // Move over a couple of pandas.
                    return tar.inject(500, inputActions.moveRight, 500, inputActions.moveRight);
                })
                .then(focusPath => {
                    verify('ad focus', focusPath, 'engagement-ad: #button_8');
                    return tar.inject(500, inputActions.select);
                })
                .then(focusPath => {
                    verify('panda detail focus', focusPath, 'engagement-ad: #button_22'); // prev panda button
                    return tar.inject(1500, inputActions.moveRight);
                })
                .then(focusPath => {
                    verify('panda detail focus', focusPath, 'engagement-ad: #button_23'); // next panda button
                    return tar.inject(100, inputActions.select);
                })
                .then(() => {
                    return tar.inject(1000, inputActions.moveDown);
                })
                .then(focusPath => {
                    verify('panda detail focus', focusPath, 'engagement-ad: #button_18'); // done button
                    return tar.inject(200, inputActions.select); // start the video
                })
                .then(() => {
                    return tar.inject(3000); // let it play for a bit
                })
                .then(focusPath => {
                    verify('video focus', focusPath, 'engagement-ad: #video_19 div video.hasFocus');
                    // toggle play pause
                    return tar.inject(
                        2000, inputActions.playPause,
                        2000, inputActions.playPause);
                })
                .then(() => {
                    return tar.inject(1000, inputActions.back, 2000); // show are you sure?
                })
                .then(focusPath => {
                    verify('are you sure focus', focusPath, 'are-you-sure: #noButton');
                    return tar.inject(1000, inputActions.moveRight);
                })
                .then(focusPath => {
                    verify('are you sure focus', focusPath, 'are-you-sure: #yesButton');
                    return tar.inject(100, inputActions.select);
                })
                .then(focusPath => {
                    verify('choice card focus', focusPath, 'choice-card: #interact-btn');
                    return tar.wait(500); // ensure that wait() works
                })
                .then(() => {
                    return tar.inject(inputActions.back);
                });
        }
    }

    // This controls the keypad on the creative draft id page
    function onCreativeDraftIdKeyPress(keypress) {
        switch (keypress) {
            case 'delete':
                _creativeDraftId = _creativeDraftId.slice(0, -1);
                break;
            case 'clear':
                _creativeDraftId = '';
                break;
            default:
                _creativeDraftId = (_creativeDraftId + keypress).slice(0, 6);
                break;
        }
        document.getElementById('creative-draft-id-preview').innerText = _creativeDraftId;
    }

    let initialPage = window.location.hash;
    const queryParams = parseQueryString(window.location.href);

    const testVastConfigUrl = queryParams['vast-config-url'];

    // Remove the query to prevent interference with future tests.
    if (!testVastConfigUrl) window.history.pushState({}, "", window.location.pathname);

    // If the startAutomation query parameter is present, open the Test Control tab before rendering the page.
    if (queryParams && queryParams.hasOwnProperty('startAutomation')) {
        // Ensure we are on the test control page.
        initialPage = "#test-control";
    }

    // Track unexpected focus changes
    // document.documentElement.addEventListener('focusin', e => {
    //     console.log('*** focusin: now: ' + getElementPath(e.target)
    //         + ' was: ' + getElementPath(e.relatedTarget)
    //         + ' active: ' + getElementPath(document.activeElement)
    //         + ' at ' + new Date()
    //         //+ '\n  at: ' + new Error('stack').stack
    //     );
    // });
    // document.documentElement.addEventListener('focusout', e => {
    //     console.log('*** focusout: was: ' + getElementPath(e.target)
    //         + ' now: ' + getElementPath(e.relatedTarget)
    //         + ' active: ' + getElementPath(document.activeElement)
    //         + ' at ' + new Date()
    //         //+ '\n  at: ' + new Error('stack').stack
    //     );
    // });

    document.body.focus();

    if (initialPage) {
        // Switch to the desired initial page.
        window.location.hash = initialPage;
    } else {
        // Render the default initial page.
        renderCurrentPage();
    }

    focusManager.blockBackActions(true);

    // Ensure the screen saver is disabled to allow test worker automation runs to keep working.
    disableScreenSaver();

    if (queryParams.hasOwnProperty('startAutomation')) {
        startAutomationTests(queryParams.sessionJobId);
    }
}());
