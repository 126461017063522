import { TruexTest } from '../truex-test/truex_test';
import { TruexLogger } from "../truex-test/truex_logger";

// A sample script captured from a live whaakapapa test session, so as to allow direct debugging locally
// of a test job flow.

// NOTE: ALERT: WARE THE DRAGONS:
// The code in this folder is a copy downloaded from previous Whakapapa runs, so it could very well be obsolete.
// Use at your own risk, and/or update as needed from actual automation test runs.

export function runLocalTestScript(testScript) {
  try {
    // Run baked in automation test to allow direct debugging of the automation test case failures.
    TruexTest.start();
    TruexLogger.info("running local test script: " + testScript);
    switch (testScript) {
      case 'testAdOptIn':
        testAdOptIn();
        break;
      case 'testAdLoadAndBackOut':
        testAdLoadAndBackOut();
        break;
      case 'testCanAutoAdvanceAfterAreYouSureYes':
        testCanAutoAdvanceAfterAreYouSureYes();
        break;
      default:
        throw new Error('unknown test method');
        break;
    }
    return TruexTest.finish();
  } catch (err) {
    console.error("[Test Script] test aborted: " + err);
    return;
  }
}

export function testAdOptIn() {
  TruexTest.launchAd("Test: HTML5 Hilton (via vastConfig)");
  TruexTest.waitForEventsInAnyOrder(20, ["adStarted", "player_load", "adFetchCompleted"]);
  TruexTest.pressSelectButton();
  TruexTest.waitForEventsInAnyOrder(30, ["initial", "optIn", "choice_card_select_unit", "unit_load"]);
  TruexTest.sleep(0);
}

export function testAdLoadAndBackOut() {
  TruexTest.launchAd("Test: HTML5 Hilton (via vastConfig)");
  TruexTest.waitForEventsInAnyOrder(20, ["adStarted", "player_load", "adFetchCompleted"]);
  TruexTest.sleep(3);
  TruexTest.pressSelectButton();
  TruexTest.waitForEventsInAnyOrder(30, ["initial", "optIn"]);
  TruexTest.sleep(0);
  TruexTest.pressSelectButton();
  TruexTest.waitForEventsInAnyOrder(6, ["video_started"]);
  TruexTest.sleep(1);
  TruexTest.pressBackButton();
  TruexTest.sleep(1);
  TruexTest.pressRightButton();
  TruexTest.sleep(1);
  TruexTest.pressSelectButton();
  TruexTest.sleep(1);
  TruexTest.pressBackButton();
  TruexTest.waitForEventsInAnyOrder(15, ["adCompleted"]);
}

export function testCanAutoAdvanceAfterAreYouSureYes() {
  TruexTest.launchAd("Test: HTML5 Hilton (via vastConfig)");
  TruexTest.waitForEventsInAnyOrder(20, ["adStarted", "player_load", "adFetchCompleted"]);
  TruexTest.pressSelectButton();
  TruexTest.waitForEventsInAnyOrder(30, ["initial", "optIn", "choice_card_select_unit", "unit_load"]);
  TruexTest.sleep(0);
  TruexTest.pressBackButton();
  TruexTest.waitForEventsInAnyOrder(5, ["display_are_you_sure"]);
  TruexTest.pressRightButton();
  TruexTest.pressSelectButton();
  TruexTest.waitForEventsInAnyOrder(10, ["slate_close", "unit_closed_by_back_before_credit", "total time spent", "userCancel"]);
  // Uncomment to back out directly instead of waiting
  TruexTest.sleep(1);
  TruexTest.pressBackButton();
  TruexTest.waitForEventsInAnyOrder(40, ["adCompleted", "optOut"]);
}