import { AwsClient } from './aws_client';

export class TestResultsClient {
    constructor(config) {

        const awsClient = (config && config.awsSqsClient) || new AwsClient();

        function postResults(testId, resultsUrl, results) {
            const message = {
                testId: testId,
                results: results
            };
            return awsClient.sendMessage(resultsUrl, message);
        }
        this.postResults = postResults;
    }
}
