import {fetch} from 'whatwg-fetch';
import {checkStatus} from './check_status';
import {v4 as uuid} from 'uuid';

export class AwsClient {
    constructor() {
        function deleteMessage(queueUrl, receiptHandle) {
            log(`Deleting message ${receiptHandle}...`);

            const data = {
                Action: "DeleteMessage",
                ReceiptHandle: receiptHandle
            };

            return postData(queueUrl, data)
                .then(checkStatus);
        }

        function receiveMessages(queueUrl) {
            log("Receiving messages...")

            const maxNumberOfMessages = 10; // The highest allowed by AWS SQS is 10; we want as many as we can, in order to find work to do.
            const visibilityTimeout = 5; // We need at least some visibility timeout in order to be able to delete messages from the queue.

            const data = {
                Action: "ReceiveMessage",
                MaxNumberOfMessages: maxNumberOfMessages,
                MessageAttributeNames: ["All"],
                VisibilityTimeout: visibilityTimeout,
                WaitTimeSeconds: 10
            };

            return postData(queueUrl, data)
                .then(checkStatus)
                .then(parseReceiveMessageResponse);
        }

        function releaseMessages(queueUrl, receiptHandles) {
            if (!Array.isArray(receiptHandles) || !receiptHandles.length) {
                return Promise.resolve();
            }

            log(`Releasing ${receiptHandles.length} messages...`);

            const entries = receiptHandles.map((receiptHandle, index) => {
                return {
                    Id: `${index}`,
                    ReceiptHandle: receiptHandle,
                    VisibilityTimeout: 0
                }
            });

            const data = {
                Action: "ChangeMessageVisibilityBatch",
                Entries: entries
            };

            return postData(queueUrl, data)
                .then(checkStatus);
        }

        function sendMessage(queueUrl, message) {
            log(`Sending a message to the queue...`);

            const data = {
                Action: "SendMessage",
                MessageBody: JSON.stringify(message),
                MessageGroupId: uuid(),
                MessageDeduplicationId: uuid()
            };

            return postData(queueUrl, data)
                .then(checkStatus);
        }

        function postData(url, data) {
            return fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
        }

        function parseReceiveMessageResponse (response) {
            return response.json()
                .then(responseObject => {
                    if (!responseObject || !responseObject.ReceiveMessageResponse
                        || !responseObject.ReceiveMessageResponse.ReceiveMessageResult
                        || !Array.isArray(responseObject.ReceiveMessageResponse.ReceiveMessageResult.messages)) {
                        return [];
                    }
                    const messages = responseObject.ReceiveMessageResponse.ReceiveMessageResult.messages;
                    log(`Found ${messages.length} messages.`);
                    return messages.map(message => {
                        const job = JSON.parse(message.Body);
                        return {
                            job: job,
                            receiptHandle: message.ReceiptHandle,
                            sessionId: job.sessionId
                        }
                    });
                });
        }

        function log(message, data) {
            console.log("[AwsSqsClient] " + message);
            if (data) console.dir(data);
        }

        this.deleteMessage = deleteMessage;
        this.receiveMessages = receiveMessages;
        this.releaseMessages = releaseMessages;
        this.sendMessage = sendMessage;
    }
}
