import 'whatwg-fetch';
import { checkStatus } from './check_status';

export class AppReloader {
    constructor(config) {
        const fetchFn = config.fetchFn || fetch;

        function defaultUrlLoader(sessionJobId) {
            const queryString = `?startAutomation&sessionJobId=${sessionJobId}&ts=${Date.now()}`;
            return url => {
                return window.reloadSkyline(`${url}${queryString}`);
            };
        }

        const urlLoader = config.urlLoader || defaultUrlLoader;

        let resumingJobSession = false;
        let jobSessionToBeResumed;

        function prepareToResumeJobSession(jobSessionId) {
            jobSessionToBeResumed = jobSessionId;
        }

        function beginResuming() {
            resumingJobSession = true;
        }

        function stopResuming() {
            resumingJobSession = false;
        }

        function reloadApp(url) {
            if (resumingJobSession) {
                return Promise.resolve('Skipping reloadApp step -- resuming session job.');
            } else {
                return verifyUrlIsOk(url)
                    .then(() => {
                        urlLoader(jobSessionToBeResumed)(url);

                        // If the current web page does not unload in time, we assume the urlLoader didn't work, and we will throw a failure.
                        const urlLoaderTimeoutSeconds = 20;
                        return new Promise((resolve, reject) => {
                            setTimeout(() => {
                                reject(new Error(`reloadApp failed -- urlLoader call completed, but page did not unload after ${urlLoaderTimeoutSeconds} seconds.`));
                            }, urlLoaderTimeoutSeconds * 1000);
                        });
                    });
            }
        }

        function verifyUrlIsOk(url) {
            return fetchFn(url)
                .then(checkStatus);
        }

        // Public functions.
        this.reloadApp = reloadApp;
        this.prepareToResumeJobSession = prepareToResumeJobSession;
        this.beginResuming = beginResuming;
        this.stopResuming = stopResuming;
    }
}
