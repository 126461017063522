function getPageParts(hash) {
    hash = decodeURI(hash);

    // Remove potential query string from the hash
    const indexOfFirstHashTag = hash.indexOf('#');
    if (indexOfFirstHashTag === 0) {
        hash = hash.split('?')[0];
    } else {
        hash = hash.substring(indexOfFirstHashTag);
    }

    const pathParts = hash.split('/');
    const tagIndex = parseInt(pathParts[1]);
    const pageKey = pathParts[0];
    return { pageKey, tagIndex, pathParts };
}

export { getPageParts };
export default getPageParts;
