import { TXMFocusManager } from 'truex-shared/focus_manager/txm_focus_manager';
import { inputActions } from 'truex-shared/focus_manager/txm_input_actions';
import { createFocusable } from './createFocusable';
import numberKeyboard from './number-keyboard.scss';

export class NumberKeyboard {
    constructor() {
        let rootDiv;
        let keyboardFocusables = [];
        let inputValue;
        let isDateField;
        let onSaveCallback = () => {};

        const focusManager = new TXMFocusManager();
        focusManager.id = 'NumberKeyboard';

        const baseOnInputAction = focusManager.onInputAction;

        function onInputAction(action) {
            if (action == inputActions.back) {
                hide();
                return;
            }

            const isNumberKey = parseInt(action) >= 0;
            if (isNumberKey) {
                onNumberKeyPress(action);
                return true;
            }

            baseOnInputAction(action);

            // Mark all actions as handled so it does not escape the modal,
            // particularly back actions.
            return true;
        }

        focusManager.onInputAction = onInputAction;

        function hide() {
            onSaveCallback = null; // release reference
            isDateField = false;
            keyboardFocusables = [];
            if (rootDiv && rootDiv.parentNode) {
                rootDiv.parentNode.removeChild(rootDiv);
                rootDiv = null;
            }

            // remove global event listeners
            focusManager.cleanup();
        }

        this.hide = hide;

        this.show = (originalValue, dateField, parentElement, saveCallback) => {
            hide();
            inputValue = originalValue || "";
            isDateField = dateField;
            onSaveCallback = saveCallback;

            rootDiv = document.createElement('div');
            rootDiv.classList.add('number-keyboard');
            rootDiv.classList.add('input-overlay');

            const valueDisplay = document.createElement('div');
            valueDisplay.classList.add("value-display");
            valueDisplay.innerText = inputValue;

            rootDiv.appendChild(valueDisplay);

            const listTag = document.createElement('ul');
            listTag.classList.add('test-buttons');

            for (let i = 1; i < 10; i++) {
                const liTag = document.createElement('li');
                liTag.id = `numberpad-${i}`;
                liTag.classList.add('action-button');
                liTag.classList.add('keypad-buttons');

                const labelTag = document.createElement('div');
                labelTag.innerText = i;
                labelTag.classList.add('label');

                liTag.appendChild(labelTag);

                listTag.appendChild(liTag);
                if (i%3 == 0) {
                    const lineBreak = document.createElement('br');
                    listTag.appendChild(lineBreak);
                }
            }

            const clearTag = document.createElement('li');
            const clearLabel = document.createElement('div');
            clearTag.id = `numberpad-clear`;
            clearTag.classList.add('action-button');
            clearTag.classList.add('keypad-buttons');
            clearLabel.innerText = 'C';
            clearLabel.classList.add('label');
            clearTag.appendChild(clearLabel);
            listTag.appendChild(clearTag);

            const zeroTag = document.createElement('li');
            const zeroLabel = document.createElement('div');
            zeroTag.id = `numberpad-0`;
            zeroTag.classList.add('action-button');
            zeroTag.classList.add('keypad-buttons');
            zeroLabel.innerText = '0';
            zeroLabel.classList.add('label');
            zeroTag.appendChild(zeroLabel);
            listTag.appendChild(zeroTag);

            const deleteTag = document.createElement('li');
            const deleteLabel = document.createElement('div');
            deleteTag.id = `numberpad-delete`;
            deleteTag.classList.add('action-button');
            deleteTag.classList.add('keypad-buttons');
            deleteLabel.innerText = '<';
            deleteLabel.classList.add('label');
            deleteTag.appendChild(deleteLabel);
            listTag.appendChild(deleteTag);

            const lineBreak = document.createElement('br');
            listTag.appendChild(lineBreak);

            const doneTag = document.createElement('li');
            const doneLabel = document.createElement('div');
            doneTag.id = `done-button`;
            doneTag.classList.add('action-button');
            doneTag.classList.add('start-buttons');
            doneLabel.innerText = 'Done';
            doneLabel.classList.add('label');
            doneTag.appendChild(doneLabel);
            listTag.appendChild(doneTag);

            rootDiv.appendChild(listTag);

            if (!parentElement) parentElement = document.body;
            parentElement.appendChild(rootDiv);

            createKeyboardFocusables();

            focusManager.captureKeyboardFocus(rootDiv);
        }

        function createKeyboardFocusables() {
            // Create the number keyboard focusables.
            keyboardFocusables = [];
            rootDiv.querySelectorAll('li').forEach(buttonDiv => {
                let buttonAction = () => {};
                switch (buttonDiv.id) {
                    case 'done-button':
                        buttonAction = () => {
                            onSaveCallback(inputValue);
                            hide();
                        };
                        break;
                    default:
                        if (buttonDiv.id.indexOf("numberpad-")==0) {
                            buttonAction = () => {
                                const keypress = buttonDiv.id.split("-")[1].toString();
                                onNumberKeyPress(keypress);
                            };
                        }
                        break;
                }
                keyboardFocusables.push(createFocusable(buttonDiv, buttonAction, focusManager));
            });
            focusManager.setContentFocusables(keyboardFocusables);
        }

        // This controls the keypad on the creative draft id page
        function onNumberKeyPress(keypress) {
            let value = inputValue;
            if (value && isDateField) {
                value = value.replaceAll('/', '');
            }

            // if the value is undefined, set it to an empty string
            if (!value) {
                value = "";
            }

            switch (keypress) {
                case 'delete':
                    value = value.slice(0, -1);
                    break;
                case 'clear':
                    value = '';
                    break;
                default:
                    value += keypress;
                    break;
            }
            
            if (value.length > 1 && isDateField) {
                value = value.slice(0, 2) + "/" + value.slice(2);
                if (value.length > 4) {
                    value = value.slice(0, 5) + "/" + value.slice(5);
                }
            }

            inputValue = value;
            rootDiv.querySelector('.value-display').innerText = value;
        }
    }
}

export default NumberKeyboard; 