import { TruexGlobalDefiner } from './truex_global_definer';
import { TruexLogger } from './truex_logger';
import { TruexTestStepsBuilder } from './truex_test_steps_builder';

import { getAdTag, getTarClass } from '../../adTagSupport';

export const TruexTest = TruexGlobalDefiner.define("TruexTest", function () {
    "use strict";

    var self = {};

    self.start = function () {
        self.reset();

        var platform = window.TruexTest.platform;

        TruexLogger.info(`Starting test.`);
        TruexLogger.info(`app build: ${(window.TruexTest.appBuild)}`);
        TruexLogger.info(`host: ${window.location.href}`);
        TruexLogger.info(`platform: ${platform.name} model: ${platform.model} version: ${platform.version}`);
        TruexLogger.info(`user agent: ${window.navigator.userAgent}`);
        return self;
    };

    self.reset = function () {
        TruexLogger.reset();
        self.stepsBuilder = new TruexTestStepsBuilder();
        self.eventListeners = {};
        self.tar = null;
    }

    self.finish = function () {
        return new Promise(function stepsAsResultsPromise(resolve) {
            self.stepsBuilder.asPromiseChain()
                .catch(handleError)
                .finally(function stopTarAndResolveWithResults() {
                    TruexLogger.info("Stopping TAR.");
                    self.tar && self.tar.stop();
                    resolve(getResults());
                });
        });
    };

    function handleError(error) {
        TruexLogger.fail("error: " + error);
    }

    function getResults() {
        return TruexLogger.getTestResults();
    }

    self.operations = {};

    self.operations.launchAd = function launchAd(tagDisplayName, supportsUserCancelStream) {
        return function() {
            TruexLogger.info("Launching ad: " + tagDisplayName + ", supportsUserCancelStream: " + supportsUserCancelStream);

            self.tar = null;

            var getTarOptions = window.TruexTest.getTarOptions || function() {return {}};
            var options = getTarOptions() || {};
            Object.assign(options, {
                whakapapa: true,
                supportsUserCancelStream: supportsUserCancelStream,
                trackingObserver: trackingEventHandler
            });

            var adTag = getAdTag(tagDisplayName);
            var vastData = adTag.vastConfig || adTag.vast_config_url;

            var tarClass = getTarClass(adTag);
            var tar = new tarClass(vastData, options);
            self.tar = tar;
            tar.subscribe(tarEventHandler);

            return tar.init().then(vastConfig => {
                if (options.engagementServerOverride) {
                    // Override the engagement as per the current branch.
                    const firstAd = vastConfig.ads && vastConfig.ads[0];
                    if (firstAd) {
                        let engagementServer = options.engagementServerOverride;
                        if (!engagementServer.endsWith('/')) engagementServer += '/';
                        firstAd.window_url = firstAd.window_url.replace(
                            /https:\/\/(qa-)?media.truex.com\/container\/3.x\/current\//, engagementServer);
                    }

                    // This is for Xtended View; xtended_view_fill is an array of engagement which is supposted to be a linear ad
                    const firstXvAd = vastConfig.ads && vastConfig.xtended_view_fill && vastConfig.xtended_view_fill[0];
                    if (firstXvAd) {
                        let engagementServer = currentBranch.engagementServerOverride;
                        if (!engagementServer.endsWith('/')) engagementServer += '/';
                        firstXvAd.window_url = firstXvAd.window_url.replace(
                            /https:\/\/(qa-)?media.truex.com\/container\/3.x\/current\//, engagementServer);
                    }
                }
                return tar.start(vastConfig);
            });
        }
    };

    self.operations.waitForEventsInAnyOrder = function waitForEventsInAnyOrder(timeoutInSeconds, expectedEvents) {
        return function () {
            var expectedEventsAsString = JSON.stringify(expectedEvents);

            var startTime = Date.now();

            TruexLogger.info("Waiting " + timeoutInSeconds + " seconds for events: " + expectedEventsAsString);

            var waits = [];
            var remainingEvents = {};
            expectedEvents.forEach(function (expectedEvent) {
                waits.push(waitForEvent(expectedEvent, remainingEvents));
                remainingEvents[expectedEvent.name] = true;
            });

            return new Promise(function waitForAllEvents(resolve, reject) {
                var timeout = setTimeout(function rejectAtTimeout() {
                    var reason = "Timed out after " + timeoutInSeconds + " seconds waiting for events: " + JSON.stringify(Object.keys(remainingEvents));
                    reject(reason);
                }, timeoutInSeconds * 1000);

                Promise.all(waits)
                    .then(function clearTimeoutAndResolve() {
                        var elapsed = Date.now() - startTime;
                        TruexLogger.info("Finished waiting after " + elapsed / 1000 + " seconds for events: " + expectedEventsAsString);
                        clearTimeout(timeout);
                        resolve();
                    });
            });
        }
    };

    self.operations.pressBackButton = function () {
        return function () {
            TruexLogger.info("Injecting back action...");
            return self.tar.inject(window.TruexTest.inputActions.back);
        }
    }

    self.operations.pressDownButton = function () {
        return function () {
            TruexLogger.info("Injecting moveDown action...");
            return self.tar.inject(window.TruexTest.inputActions.moveDown);
        }
    }

    self.operations.pressLeftButton = function () {
        return function () {
            TruexLogger.info("Injecting moveLeft action...");
            return self.tar.inject(window.TruexTest.inputActions.moveLeft);
        }
    }

    self.operations.pressRightButton = function () {
        return function () {
            TruexLogger.info("Injecting moveRight action...");
            return self.tar.inject(window.TruexTest.inputActions.moveRight);
        }
    }

    self.operations.pressSelectButton = function () {
        return function () {
            TruexLogger.info("Injecting select action...");
            return self.tar.inject(window.TruexTest.inputActions.select);
        }
    }

    self.operations.sleep = function (seconds) {
        return function () {
            TruexLogger.info("Sleeping for " + seconds + " seconds...");
            return new Promise(function (resolve) {
                setTimeout(function () {
                    resolve();
                }, seconds * 1000);
            });
        }
    }

    self.operations.repeatedlyOptInUntilTwoDifferentVideosPlay = function (
        optInFunction,
        optInAgainFunction,
        observeVideoPlaybackFunction,
        backOutOfEngagementFunction) {

        return function () {
            TruexLogger.info("Repeatedly opting in until two different videos play...");
            var maxAttempts = 10;
            var attemptsSoFar = 0;
            var firstVideoSeen = "";
            var firstTime = true;

            function determineCorrectOptInFunction() {
                if (firstTime) {
                    firstTime = false;
                    return optInFunction;
                } else {
                    return optInAgainFunction;
                }
            }

            return new Promise(function (resolve, reject) {
                function doTheWholeThing() {
                    var correctOptInFunction = determineCorrectOptInFunction();
                    return correctOptInFunction().then(function () {
                        return observeVideoPlaybackFunction().then(function (videoName) {
                            if (!firstVideoSeen) {
                                firstVideoSeen = videoName;
                                attemptsSoFar++;
                            } else if (firstVideoSeen) {
                                if (firstVideoSeen !== videoName) {
                                    resolve();
                                }
                                attemptsSoFar++;
                            }
                            if (attemptsSoFar === maxAttempts) {
                                reject("Tried max number of times to get a different video.");
                            }
                            return backOutOfEngagementFunction().then(function () {
                                return doTheWholeThing();
                            });
                        });
                    });
                }

                return doTheWholeThing();
            });
        }
    }

    self.operations.waitForVideoFirstQuartile = function (timeoutInSeconds) {
        return function () {
            var eventName = "video_first_quartile";
            return new Promise(function (resolve, reject) {
                var timeout = setTimeout(function rejectAtTimeout() {
                    var reason = "Timed out after " + timeoutInSeconds + " seconds waiting for event: " + eventName;
                    reject(reason);
                }, timeoutInSeconds * 1000);
                callOnNextEvent(eventName, function(value) {
                    clearTimeout(timeout);
                    resolve(value);
                });
            });
        }
    }

    self.operations.verifyNoErrorEvents = function () {
        return function () {
            var allEvents = TruexLogger.getAllEvents();

            for (var i = 0; i < allEvents.length; i++) {
                var event = allEvents[i];
                if (isAdErrorEvent(event) || isTrackingErrorEvent(event)) {
                    return Promise.reject("Found an error event: " + JSON.stringify(event))
                }
            }

            var passMessage = "No error events found.";
            TruexLogger.info(passMessage)
            return Promise.resolve(passMessage);
        }
    }

    function waitForEvent(expectedEvent, remainingEvents) {
        return new Promise(function (resolve) {
            callOnNextEvent(expectedEvent.name, function(incomingEvent) {
                var requiredConditions = expectedEvent.condition;
                var conditionsMet = true;
                if (requiredConditions) {
                    Object.keys(requiredConditions).forEach(function (key) {
                        var expectedValue = requiredConditions[key];
                        if (typeof expectedValue === "string") {
                            if (expectedValue !== incomingEvent[key]                       // Check for string equality
                                && !incomingEvent[key].match(new RegExp(expectedValue))) { // Expected value may also be a regex to match
                                conditionsMet = false;
                            }
                        } else if (expectedValue !== incomingEvent[key]) {
                            conditionsMet = false;
                        }
                    });
                }
                if (conditionsMet) {
                    delete remainingEvents[expectedEvent.name];
                    resolve(incomingEvent);
                }
            });
        });
    }

    function callOnNextEvent(eventName, callback) {
        function listener(value) {
            removeTruexEventListener(eventName, listener);
            callback(value);
        }
        if (!self.eventListeners[eventName]) self.eventListeners[eventName] = [];
        self.eventListeners[eventName].push(listener);
    }

    function removeTruexEventListener(eventName, listener) {
        if (self.eventListeners[eventName] && self.eventListeners[eventName].length > 0) {
            var index = self.eventListeners[eventName].indexOf(listener);
            if (index > -1) {
                self.eventListeners[eventName].splice(index, 1);
            }
        }
    }

    function trackingEventHandler(category, name, value, isInteraction) {
        const isPixel = category === 'pixel';
        const prefix = isPixel ? 'pixel' : isInteraction ? 'interaction' : 'tracking';
        let msg = `Got ${prefix}: ${isPixel ? name : category}, ${name}`;
        if (value) msg += ', ' + value;
        TruexLogger.info(msg);

        // Need to URL encode event names to match TAR-Roku behavior; see `total time spent` (there may be others)
        const urlEncodedName = encodeURIComponent(name);
        const reconstructedEvent = {
            category: category,
            name: urlEncodedName,
            value: value,
            isInteraction: isInteraction
        };
        TruexLogger.recordEvent(reconstructedEvent);

        fireEventListeners(urlEncodedName, reconstructedEvent);
    }

    function tarEventHandler(tarEvent) {
        TruexLogger.info(`Got ad event '${tarEvent.type}': ${JSON.stringify(tarEvent)}`);
        TruexLogger.recordEvent({
            category: null,
            name: tarEvent.type,
            value: tarEvent
        });
        fireEventListeners(tarEvent.type, tarEvent);
    }

    function fireEventListeners(eventName, event) {
        var listeners = self.eventListeners[eventName];
        if (listeners && listeners.length > 0) {
            listeners = listeners.concat(); // make a copy
            listeners.forEach(function fireListener(listener) {
                listener(event);
            });
        }
    }

    function isAdErrorEvent (event) {
        return event.name === "adError";
    }

    function isTrackingErrorEvent (event) {
        return event.category === "error"
    }

    self.launchAd = function (tagDisplayName, supportsUserCancelStream) {
        self.stepsBuilder.addAction(self.operations.launchAd(tagDisplayName, supportsUserCancelStream));
    };

    self.waitForEventsInAnyOrder = function (timeoutInSeconds, events) {
        self.stepsBuilder.addWait(self.operations.waitForEventsInAnyOrder(timeoutInSeconds, events));
    };

    self.pressBackButton = function () {
        self.stepsBuilder.addAction(self.operations.pressBackButton());
    };

    self.pressDownButton = function () {
        self.stepsBuilder.addAction(self.operations.pressDownButton());
    };

    self.pressLeftButton = function () {
        self.stepsBuilder.addAction(self.operations.pressLeftButton());
    };

    self.pressRightButton = function () {
        self.stepsBuilder.addAction(self.operations.pressRightButton());
    };

    self.pressSelectButton = function () {
        self.stepsBuilder.addAction(self.operations.pressSelectButton());
    };

    self.sleep = function (seconds) {
        self.stepsBuilder.addAction(self.operations.sleep(seconds));
    };

    self.repeatedlyOptInUntilTwoDifferentVideosPlay = function (
        optInFunction,
        optInAgainFunction,
        observeVideoPlaybackFunction,
        backOutOfEngagementFunction) {

        self.stepsBuilder.addAction(self.operations.repeatedlyOptInUntilTwoDifferentVideosPlay(
            optInFunction, optInAgainFunction, observeVideoPlaybackFunction, backOutOfEngagementFunction));
    };

   self.verifyNoErrorEvents = function () {
       self.stepsBuilder.addAction(self.operations.verifyNoErrorEvents());
   }

    return self;
});
