// Describes the possible branches of skyline that are hosted so that a copy of Skyline can be run from them for testing.
const branches = [
    {label: "develop (Current QA Version)", host: "https://skyline.truex.com"},

    {
        label: "github actions deploy: C3 feature branch test",
        branch: "task/PI-2946/migrate-from-travis-to-github-actions",
        overrideIC: false,
        overrideC3: true,
    },
    {
        label: "github actions deploy: C3 release branch test",
        branch: "task/PI-2946/test-C3-release-3.19.0",
        overrideIC: false,
        overrideC3: true,
        c3Branch: "release/3.19.0"
    },
    {
        label: "Prod Deployment Cache Test",
        branch: "fix/PI-2593/deployment-cache-fixes",
        overrideIC: true,
        overrideC3: true,
        useProd: true
    },
    {
        label: "QA Deployment Cache Test",
        branch: "fix/PI-2593/deployment-cache-fixes",
        overrideIC: true,
        overrideC3: true
    },
    {
        label: "Deeplink on Close Test",
        branch: "feature/add-additional-params-support",
        overrideC3: true,
        c3Branch: "hotfix/3.17.1"
    },

    // Devs should manually push prev versions of qa deployments of Skyline/IC/C3 as needed by
    // QA to reproduce bugs in older builds.
    //{label: "Previous QA Snapshot", branch: "release/qa-snapshot", overrideIC: true, overrideC3: true},

    //{branch: "release/1-8-1", overrideIC: true, overrideC3: true},
    //{branch: "release/1-7-4", overrideIC: true, overrideC3: true},

    // {label: "TAR 1.5.8 with current QA/prod", branch: "release/TAR-1.5.8-with-latest", overrideIC: false, overrideC3: false},

    // {
    //     label: "Skyline: 1-7-0, CC: 1-6-1, IC: 1-6-5",
    //     branch: "develop",
    //     icBranch: "release/1-6-5",
    //     c3Branch: "release/1-6-1"
    // },

    // {
    //     label: "Skyline: 1-7-0, CC: 1-6-1, IC: 1-6-4",
    //     branch: "develop",
    //     icBranch: "release/1-6-4",
    //     c3Branch: "release/1-6-1"
    // },
    //{branch: "release/1-6-0", overrideIC: true, overrideC3: true},
    // {branch: "release/1-5-2", overrideIC: true, overrideC3: false},
    // {branch: "release/1-5-1", overrideIC: true, overrideC3: true},
    //{branch: "release/1-5-0", overrideIC: true, overrideC3: true},

    // Feature branches can be added in the following styles:

    // {
    //     branch: "feature/CTV-2947-move-deploy-scripts-to-truex-shared",
    //     icBranch: "feature/CTV-2947/s3-deploy-specifies-no-cache",
    //     c3Branch: "feature/CTV-2947/s3-deploy-specifies-no-cache",
    //     useRawS3: true
    // },

    // {branch: "bugfix/CTV-2905-fix-funimation-crash", overrideIC: true, overrideC3: false, useRawS3: true},
    // {branch: "bugfix/ctv-2767-xbox-one-focus-stuck", overrideIC: true, overrideC3: true},
    // {branch: "task/CTV-2766-add-android-tv-support", overrideIC: true, overrideC3: true, useRawS3: true},

    // release 1-1-0 is now in prod
    // {branch: "release/1-1-0", overrideIC: true, overrideC3: true},
    //
    // {   // For exercising release TAR-1.0 usages with PROD IC and C3 versions.
    //     label: "PROD TAR 1.0 with PROD stack",
    //     branch: "release/1-1-0-with-TAR-1.0-prod-stack"
    // },
    //
    // {   // For exercising release TAR-1.0 usages with release 1-1-0 IC and C3 versions.
    //     // This corresponds to client apps like Crackle that will not be able to update their
    //     // TAR libraries for a while.
    //     label: "PROD TAR 1.0 with release/1-1-0 stack",
    //     branch: "release/1-1-0-with-TAR-1.0.24",
    //     icBranch: "release/1-1-0",
    //     c3Branch: "release/1-1-0"
    // },

    // "feature/CTV-2637-allow-skyline-branch-loading",
    // {branch: "feature/CTV-2505-Enhanced-Choice-Card-authoring-for-HTML5-TAR", overrideIC: true, overrideC3: true},
];

// Used for local development, debugging.
export function createLocalBranch(localHost) {
   return fillBranch({
       label: `Local Development: ${localHost}`,
       host: `http://${localHost}:8080`,
       choiceCardServerOverride: `http://${localHost}:8001`,
       engagementServerOverride: `http://${localHost}:9090`,
   });
}

export const featureBranches = branches.map(fillBranch);
export default featureBranches;

function fillBranch(branchItem) {
    const branchName = (typeof branchItem == 'string') ? branchItem : branchItem.branch;
    const branchPath = getBranchPath(branchName);

    // Use S3 link directly to minimize caching issues.
    const rawS3Server = "https://s3.amazonaws.com/qa-media.truex.com/branch-test";
    const qaMediaServer = "https://qa-media.truex.com/branch-test";
    const prodMediaServer = "https://media.truex.com/branch-test";

    const branchServer = branchItem.useRawS3 ? rawS3Server
        : branchItem.useProd ? prodMediaServer
        : qaMediaServer;

    // integration_core branch override:
    const overrideIC = branchItem.icBranch || branchName && branchItem.overrideIC;
    const icBranchPath = getBranchPath(branchItem.icBranch || branchName);
    const choiceCardServerOverride = branchItem.choiceCardServerOverride
        || overrideIC && `${branchServer}/integration/${icBranchPath}`

    // C3 container_code branch override:
    const overrideC3 = branchItem.c3Branch || branchName && branchItem.overrideC3;
    const c3BranchPath = getBranchPath(branchItem.c3Branch || branchName);
    const engagementServerOverride = branchItem.engagementServerOverride
        || overrideC3 && `${branchServer}/container/${c3BranchPath}`

    return {
        label: branchItem.label || branchName || branchItem.host,
        branch: branchName,
        host: branchItem.host || `${qaMediaServer}/skyline/${branchPath}/index.html`,
        choiceCardServerOverride: choiceCardServerOverride,
        engagementServerOverride: engagementServerOverride
    };
}

function getBranchPath(branchName) {
    return branchName && branchName.replace(/[^a-z0-9_\-]/gi, '_').toLowerCase();
}
