function parseQueryString(href) {
    const parsed = {};

    href = decodeURI(href);
    let query = href.split('?')[1];

    if (query && query.length > 0) {
        // Remove trailing hash if present
        query = query.split('#')[0];

        const keyValuePairs = query.split('&');
        keyValuePairs.forEach(pair => {
            const firstEqualsIndex = pair.indexOf('=');
            let key, value;
            if (firstEqualsIndex > -1) {
                key = pair.substring(0, firstEqualsIndex);
                value = decodeURIComponent(pair.substring(firstEqualsIndex + 1));
            } else {
                key = pair;
            }
            parsed[key] = value;
        });
    }
    return parsed;
}

export {parseQueryString};
export default parseQueryString;
