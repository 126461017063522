const backgroundId = "TestWorkerScreenSaverBackground"
const floatingTextId = "TestWorkerScreenSaverFloatingText"
const onKeyHideTimeMs = 10000;

export class ScreenSaver {
    constructor(config) {
        const platformName = config.platformName || "platformName undefined";

        const addKeydownListener = config.addKeydownListener || function(listener) {
            window.addEventListener("keydown", listener);
        };

        const show = config.show || function() {
            showBackground();
            showFloatingText();
        }

        const hide = config.hide || function() {
            let element = document.querySelector(`#${backgroundId}`);
            if (!element) {
                return;
            }
            element.style.display = "none";
        }

        let waitingTimeout = null;
        let resolveWait = null;
        let waitPromise = null;
        const wait = config.wait || function() {
            if (!waitingTimeout) {
                waitPromise = new Promise(resolve => {
                    resolveWait = resolve;
                    waitingTimeout = startWaitingTimeout(resolve);
                });
                return waitPromise;
            } else {
                prolongWait();
                return waitPromise;
            }
        }

        function startWaitingTimeout(resolve) {
            return setTimeout(() => {
                waitingTimeout = null;
                resolveWait = null;
                waitPromise = null;
                resolve();
            }, onKeyHideTimeMs);
        }

        const prolongWait = config.prolongWait || function () {
            if (!waitingTimeout) {
                return;
            }
            clearTimeout(waitingTimeout);
            waitingTimeout = startWaitingTimeout(resolveWait)
        }

        addKeydownListener(() => {
            if (activated) {
                hide();
                wait().then(() => {
                    if (activated) {
                        show();
                    }
                });
            }
        });

        let activated = false;

        function activate() {
            if (activated) {
                return;
            }
            activated = true;
            show();
        }

        function deactivate() {
            if (!activated) {
                return;
            }
            activated = false;
            hide();
            removeFloatingText();
            removeBackground();
        }

        function showBackground() {
            let element = getBackgroundElement();
            element.style.display = "block";
        }

        function getBackgroundElement() {
            let element = document.querySelector(`#${backgroundId}`);
            if (!element) {
                element = createBackgroundElement();
            }
            return element;
        }

        function createBackgroundElement() {
            const element = document.createElement("div");
            element.id = backgroundId;
            element.style.position = "absolute";
            element.style.width = "100%";
            element.style.height = "100%";
            element.style.left = "0px";
            element.style.top = "0px";
            element.style.display = "block";
            element.style.zIndex = "9999";
            element.style.backgroundColor = "black";
            document.body.appendChild(element);
            return element;
        }

        function showFloatingText() {
            let element = getFloatingTextElement();

            if (!element.animate) {
                // Just don't show any text.
                element.style.display = "none";
                return;
            }

            let to = {
                x: element.parentElement.clientWidth - 100,
                y: element.parentElement.clientHeight
            }

            let yDuration = 20000;
            let xDuration = yDuration * to.x / to.y; // Should provide nearly same X and Y speed

            element.animate([
                { transform: "translateY(0)" },
                { transform: "translateY("+to.y+"px)" }
            ], {
                duration: yDuration,
                direction: "alternate",
                iterations: Infinity
            });
            element.animate([
                { transform: "translateX(0)" },
                { transform: "translateX("+to.x+"px)" }
            ], {
                duration: xDuration,
                direction: "alternate",
                iterations: Infinity,
                composite: "add"
            });
        }

        function getFloatingTextElement() {
            let element = document.querySelector(`#${floatingTextId}`);
            if (!element) {
                element = createFloatingTextElement();
            }
            return element;
        }

        function createFloatingTextElement() {
            const element = document.createElement("div");
            element.id = floatingTextId;
            element.style.position = "absolute";
            element.style.left = "0px";
            element.style.top = "0px";
            element.style.backgroundColor = "black";
            element.style.color = "white";
            element.textContent = `${platformName} looking for work...`;

            let backgroundElement = getBackgroundElement();
            backgroundElement.appendChild(element);
            return element;
        }

        function removeFloatingText() {
            removeElement(floatingTextId);
        }

        function removeBackground() {
            removeElement(backgroundId);
        }

        function removeElement(id) {
            let element = document.querySelector(`#${id}`);
            if (!element) {
                return;
            }
            element.remove();
        }

        this.activate = activate;
        this.deactivate = deactivate;
    }
}
