import { TruexGlobalDefiner } from './truex_global_definer';

export const TruexLogger = TruexGlobalDefiner.define("TruexLogger", function () {
    "use strict";

    var TruexLogger = {};

    var testResults = [];

    var events = [];

    var level = {
        fail: "fail",
        pass: "pass",
        info: "info"
    };

    function getTimestamp() {
        return Date.now() - TruexLogger.startTime;
    }

    function logToConsole(timestamp, type, message) {
        console.log("[TruexTest] [" + timestamp + "] " + (type ? "[" + type + "] " : "") + message);
    }

    function addToTestResults(timestamp, type, message) {
        testResults.push({
            timestamp: timestamp,
            type: type,
            message: message
        });
    }

    function log(type, message) {
        var timestamp = getTimestamp();
        logToConsole(timestamp, type, message);
        addToTestResults(timestamp, type, message);
    }

    TruexLogger.info = function (message) {
        log(level.info, message);
    };

    TruexLogger.pass = function (message) {
        log(level.pass, message);
    };

    TruexLogger.fail = function (message) {
        log(level.fail, message);
    };

    TruexLogger.reset = function () {
        testResults = [];
        events = [];
        TruexLogger.startTime = Date.now();
    };

    TruexLogger.getTestResults = function () {
        return testResults.concat(); // make a copy
    };

    TruexLogger.recordEvent = function (event) {
        events.push(event);
    }

    TruexLogger.getAllEvents = function () {
        return events.concat();
    }

    return TruexLogger;
});
